import React, { SyntheticEvent, useRef } from 'react';
import { Grid } from '@giphy/react-components';
import { GiphyFetch, gifPaginator } from '@giphy/js-fetch-api';
import config from '../config.json';
import { IonPopover, IonSearchbar, IonContent } from '@ionic/react';
import '../styles/components/GiphyPicker.scss';
import { IGif } from '@giphy/js-types';
import { isPlatform } from '@ionic/core';

export interface GiphyPickerProps {
  isOpen: boolean;
  onSelected?: (e: IGif) => void;
  onDidDismiss?: (e: any) => void;
}

const GiphyPicker: React.FC<GiphyPickerProps> = ({
  isOpen,
  onSelected,
  onDidDismiss
}) => {
  const grid = useRef<any>();

  const onClick = (gif: IGif, e: SyntheticEvent<HTMLElement, Event>) => {
    e.preventDefault();
    e.stopPropagation();
    if (onSelected) {
      onSelected(gif);
    }
  };

  const fetch = new GiphyFetch(config.giphy.apiKey);

  const fetcher = (offset: number) => fetch.trending({ limit: 10, offset });

  return (
    <IonPopover
      mode={isPlatform('android') ? 'md' : 'ios'}
      isOpen={isOpen}
      // cssClass="giphy-picker"
      className="giphy-picker"
      onDidDismiss={onDidDismiss}
    >
      <IonSearchbar
        mode={isPlatform('android') ? 'md' : 'ios'}
        onIonChange={(e: any) => {
          const searchValue = e.detail.value;
          let g = grid.current;
          //HACK: Absolute hack to get searching working.
          if (g) {
            const searcher = (offset: number) =>
              fetch.search(searchValue, { limit: 10, offset });
            g.paginator = gifPaginator(searchValue ? searcher : fetcher);
            g.setState({
              gifs: [],
              isLoaderVisible: true,
              isDoneFetching: false
            });
            g.onFetch();
          }
        }}
        placeholder="Search GIFs"
      />
      <IonContent>
        {isOpen && (
          <Grid
            ref={grid}
            width={320}
            columns={2}
            gutter={6}
            fetchGifs={fetcher}
            onGifClick={onClick}
          />
        )}
      </IonContent>
    </IonPopover>
  );
};

export default GiphyPicker;
