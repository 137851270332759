import React from 'react';
import { TItemProps, default as TItem } from './TItem';
import { IonToggle } from '@ionic/react';

export interface TToggleItemProps extends TItemProps {
  checked: boolean;
  color?: HTMLIonToggleElement['color'];
  disabled?: boolean;
  onIonChange?: (e: any) => any;
}

const TToggleItem: React.FC<TToggleItemProps> = ({
  color = 'success',
  checked,
  onIonChange,
  children,
  disabled,
  ...rest
}) => {
  return (
    <TItem {...rest}>
      <IonToggle
        checked={checked}
        disabled={disabled}
        color={color}
        onClick={(e: any) =>
          onIonChange?.(
            new CustomEvent('onIonChange', {
              detail: { checked: e.target.checked }
            })
          )
        }
      />
      {children}
    </TItem>
  );
};

export default TToggleItem;
