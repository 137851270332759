import React, { useState, useCallback, useRef } from 'react';
import TModal from './TModal';
import { inventoryService, urlShortenerService } from '../../services';
import {
  IonList,
  IonItem,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonToolbar,
  IonBadge
} from '@ionic/react';
import numeral from 'numeral';
import TSearchBar from '../TSearchBar';
import { TTextItem } from '..';
import { util, browser, loadingIndicator, sentry } from '../../core';
import ButtonGrid from '../ButtonGrid';
import '../../styles/components/InventorySearchModal.scss';
import { modalController } from '@ionic/core';
import { heart } from 'ionicons/icons';
import TExpandableItemGroup from '../TExpandableItemGroup';
import { INVENTORY_DEFAULT_PRICE_RANGE, INVENTORY_DEFAULT_MILEAGE_RANGE } from '../../services/inventory';
import InventoryFilters from '../InventoryFilters';
import { useDataLoader } from '../../hooks';

export interface InventorySearchModalProps {
  onDidDismiss?: () => void;
  likedIds?: any[];
  clientId: number;
  leadId: number;
  leadHash: string;
  onLike?: (id: any) => any;
  onDislike?: (id: any) => any;
  onSend?: (url: string) => any;
  onAttachImage?: (url: string) => any;
}

const InventorySearchModal: React.FC<InventorySearchModalProps> = ({
  onDidDismiss,
  likedIds = [],
  clientId,
  leadHash,
  onLike,
  onDislike,
  onSend,
  onAttachImage
}) => {
  const [condition, setCondition] = useState('');
  const [search, setSearch] = useState('');
  const [bodyStyle, setBodyStyle] = useState<any>();
  const [ordering, setOrdering] = useState<any>('');
  const [yearName, setYearName] = useState<any>();
  const [makeName, setMakeName] = useState<any>();
  const [exteriorColorName, setExteriorColorName] = useState<any>();
  const [driveTypeName, setDriveTypeName] = useState<any>();
  const [transmissionTypeName, setTransmissionTypeName] = useState<any>();
  const [engineName, setEngineName] = useState<any>();
  const [fuelTypeName, setFuelTypeName] = useState<any>();
  const [modelName, setModelName] = useState<any>();
  const [trimName, setTrimName] = useState<any>();
  const [priceMin, setPriceMin] = useState<any>(
    INVENTORY_DEFAULT_PRICE_RANGE.Min
  );
  const [priceMax, setPriceMax] = useState<any>(
    INVENTORY_DEFAULT_PRICE_RANGE.Max
  );
  const [mileageMin, setMileageMin] = useState<any>(
    INVENTORY_DEFAULT_MILEAGE_RANGE.Min
  );
  const [mileageMax, setMileageMax] = useState<any>(
    INVENTORY_DEFAULT_MILEAGE_RANGE.Max
  );
  const [clientPriceMin] = useState<any>(INVENTORY_DEFAULT_PRICE_RANGE.Min);
  const clientPriceMax = useRef<any>();

  const loadClientPricing = async (clientId: number) => {
    let max = INVENTORY_DEFAULT_PRICE_RANGE.Max;
    try {
      const { max } = await inventoryService.getRangeLimits(clientId);
      clientPriceMax.current = max;
      return max;
    } catch (e) {
      sentry.capture(e as Error);
    }

    clientPriceMax.current = max;
    return max;
  };

  const load = useCallback(async () => {
    let max = clientPriceMax.current;

    if (!max) {
      max = await loadClientPricing(clientId);
    }

    const res = await inventoryService.list({
      clientId,
      search,
      bodyStyle,
      ordering,
      yearName,
      makeName,
      exteriorColorName,
      driveTypeName,
      transmissionTypeName,
      engineName,
      fuelTypeName,
      modelName,
      trimName,
      priceMin,
      priceMax: Math.min(priceMax, max),
      mileageMin,
      mileageMax,
      condition
    });
    return res;
  }, [bodyStyle,
      ordering,
      yearName,
      makeName,
      exteriorColorName,
      driveTypeName,
      transmissionTypeName,
      engineName,
      fuelTypeName,
      modelName,
      trimName,
      priceMin,
      priceMax,
      mileageMin,
      mileageMax,
      condition,
      search,
      clientId]);

  const { data, count, loading, hasNext, loadNext } = useDataLoader(load, true);

  const getInventoryUrl = (id: number) =>
    inventoryService.getInventoryUrl(leadHash, id);

  return (
    <TModal
      title="Search Inventory"
      isOpen={true}
      onDidDismiss={onDidDismiss}
      className="inventory-modal"
      loading={loading}
      toolbar={
        <IonToolbar className="ion-multiline">
          <div className="inventory-search-bar">
            <TSearchBar onSearch={(val: string) => setSearch(val)} />
            <IonBadge mode="ios">{count}</IonBadge>
          </div>
          <TExpandableItemGroup title="Filters">
            <InventoryFilters
              clientId={clientId}
              bodyStyle={bodyStyle}
              ordering={ordering}
              yearName={yearName}
              makeName={makeName}
              exteriorColorName={exteriorColorName}
              driveTypeName={driveTypeName}
              transmissionTypeName={transmissionTypeName}
              engineName={engineName}
              fuelTypeName={fuelTypeName}
              modelName={modelName}
              trimName={trimName}
              condition={condition}
              priceMin={priceMin}
              priceMax={priceMax}
              mileageMin={mileageMin}
              mileageMax={mileageMax}
              clientMin={clientPriceMin}
              clientMax={clientPriceMax.current}
              onConditionChange={setCondition}
              onPriceMinChange={setPriceMin}
              onPriceMaxChange={setPriceMax}
              onMileageMinChange={setMileageMin}
              onMileageMaxChange={setMileageMax}
              onBodyStyleChange={setBodyStyle}
              onOrderingChange={setOrdering}
              onYearNameChange={setYearName}
              onMakeNameChange={setMakeName}
              onExteriorColorNameChange={setExteriorColorName}
              onDriveTypeNameChange={setDriveTypeName}
              onTransmissionTypeNameChange={setTransmissionTypeName}
              onEngineNameChange={setEngineName}
              onFuelTypeNameChange={setFuelTypeName}
              onModelNameChange={setModelName}
              onTrimNameChange={setTrimName}
            />
          </TExpandableItemGroup>
        </IonToolbar>
      }
    >
      <IonRefresher
        slot="fixed"
        onIonRefresh={async e => {
          await load();
          e.detail.complete();
        }}
      >
        <IonRefresherContent />
      </IonRefresher>
      <IonList lines="none">
        {data?.map((it: any) => {
          const liked = likedIds?.indexOf?.(it.id) > -1;
          return (
            <IonItem key={it.id}>
              <IonCard>
                <img
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src="/assets/car-placeholder.jpg";
                  }}
                  alt={it.vin}
                  src={it.default_image}
                />
                <IonCardHeader>
                  <IonCardSubtitle>{it.condition}</IonCardSubtitle>
                  <IonCardTitle>
                    {it.year} {it.make} {it.model} {it.trim}
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <TTextItem
                          className="no-padding"
                          label="Stock #"
                          labelPosition="stacked"
                          text={it.stock_number}
                        />
                      </IonCol>
                      <IonCol>
                        <TTextItem
                          className="no-padding"
                          label="Mileage"
                          labelPosition="stacked"
                          text={numeral(it.mileage).format('0,0')}
                        />
                      </IonCol>
                      <IonCol>
                        <TTextItem
                          className="no-padding"
                          label="Color"
                          labelPosition="stacked"
                          text={it.exterior_color}
                        />
                      </IonCol>
                      <IonCol>
                        <TTextItem
                          className="no-padding"
                          label="Price"
                          labelPosition="stacked"
                          text={inventoryService.getSellingPrice(
                            it.selling_price
                          )}
                        />
                      </IonCol>
                      <IonCol>
                        <TTextItem
                          className="no-padding"
                          label="MSRP"
                          labelPosition="stacked"
                          text={util.formatDollar(it.msrp)}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  <ButtonGrid columns={[2, 2, 4, 4, 12]}>
                    <IonButton
                      color={liked ? 'danger' : 'primary'}
                      onClick={async () => {
                        if (liked) {
                          onDislike?.(it);
                        } else {
                          onLike?.(it);
                        }
                      }}
                    >
                      <IonIcon icon={heart} />
                    </IonButton>
                    <IonButton
                      expand="block"
                      onClick={() => browser.open(getInventoryUrl(it.id))}
                    >
                      View
                    </IonButton>

                    {onAttachImage && it?.default_image && (
                      <IonButton
                        expand="block"
                        onClick={(e: any) => {
                          e.preventDefault();
                          onAttachImage(it.default_image);
                          modalController.dismiss();
                        }}
                      >
                        Send Photo
                      </IonButton>
                    )}

                    {onSend && it?.carfax_url && (
                      <IonButton
                        expand="block"
                        onClick={async () => {
                          try {
                            await loadingIndicator.create();
                            const {
                              short_url
                            } = await urlShortenerService.shorten(it.carfax_url);
                            onSend(short_url ?? it.carfax_url);
                            modalController.dismiss();
                          } finally {
                            loadingIndicator.dismiss();
                          }
                        }}
                      >
                        Send Carfax
                      </IonButton>
                    )}
                    {onSend && (
                      <IonButton
                        color="secondary"
                        expand="block"
                        onClick={async () => {
                          try {
                            await loadingIndicator.create();
                            const url = getInventoryUrl(it.id);
                            const {
                              short_url
                            } = await urlShortenerService.shorten(url);
                            onSend(short_url ?? url);
                            modalController.dismiss();
                          } finally {
                            loadingIndicator.dismiss();
                          }
                        }}
                      >
                        Send
                      </IonButton>
                    )}
                  </ButtonGrid>
                </IonCardContent>
              </IonCard>
            </IonItem>
          );
        })}
      </IonList>
      <IonInfiniteScroll
        disabled={!hasNext}
        onIonInfinite={async (e: any) => {
          await loadNext();
          e.target.complete();
        }}
      >
        <IonInfiniteScrollContent />
      </IonInfiniteScroll>
    </TModal>
  );
};

export default InventorySearchModal;
