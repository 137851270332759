import React, { useContext } from 'react';
import TExpandableItemGroup from './TExpandableItemGroup';
import { IonList, IonLabel, IonItemDivider, IonText } from '@ionic/react';
import { LeadContext } from '../context/LeadContext';
import TItem from './TItem';
import TTextItem from './TTextItem';
import TAvatar from './TAvatar';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import { browser } from '../core';

function stripHTMLAndRetainLineBreaks(html: string) {
    // Convert <br> and </p> to newline
    let text = html.replace(/<br\s*\/?>/gi, "\n").replace(/<\/p\s*\/?>/gi, "\n");

    // Use a DOMParser to strip other HTML tags
    let doc = new DOMParser().parseFromString(text, 'text/html');
    return doc.body.textContent || "";
}

const LeadNotesModal: React.FC = (props) => {
  const { state } = useContext(LeadContext);
  const { leadClient } = state;
  const { performance_manager, profile } = leadClient ?? {};
  const address = `${leadClient.address} ${leadClient.address_2} ${leadClient.city} ${leadClient.state} ${leadClient.postal_code}`;
  return (
    <TExpandableItemGroup title="Notes" defaultExpanded={state.showLeadNotesModal}>
      {profile ? (
        <IonList lines="full" className="no-margin no-padding">
          <IonItemDivider>Performance Manager</IonItemDivider>
          <TItem
            copyText={`${performance_manager?.full_name} • ${performance_manager?.cell_phone} • ${performance_manager?.email}`}
          >
            <TAvatar
              src={performance_manager?.profile_pic}
              email={performance_manager?.email}
              alt={performance_manager?.full_name}
              slot="start"
            />
            <IonLabel className="ion-multiline">
              {performance_manager?.full_name}
              <p className="detail">
                {performance_manager?.cell_phone} •{' '}
                {performance_manager?.email}
              </p>
            </IonLabel>
          </TItem>
          <IonItemDivider>Client Info</IonItemDivider>
          <TTextItem labelPosition="stacked" label="Address" text={address} showCopy />
          <TTextItem labelPosition="stacked" label="Phone" text={leadClient.phone} showCopy />
          <TTextItem labelPosition="stacked" label="Main Contact" text={profile.contact} showCopy />
          <TTextItem labelPosition="stacked" label="Ask For" text={profile.ask_for} showCopy />
          <TItem label="COVID-19 Policy" copyText={leadClient.health_policy}>
            {leadClient.health_policy ? (
              <button
                className="link-button"
                onClick={() => browser.open(leadClient.health_policy)}
              >
                View Policy
              </button>
            ) : (
              <p>No Policy On File</p>
            )}
          </TItem>
          <IonItemDivider>Offers</IonItemDivider>
          <TItem className="t-display-block" copyText={profile.offers}>
            <IonText>
              <ReactMarkdown
                skipHtml={false}
                rehypePlugins={[rehypeRaw]}
                children={profile.offers || 'No Offers'}

              />
            </IonText>
          </TItem>
          <IonItemDivider>Notes</IonItemDivider>
          <TItem className="t-display-block" copyText={stripHTMLAndRetainLineBreaks(profile.notes)}>
            <IonText>
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                skipHtml={false}
                children={profile.notes ?? ''}
               />
            </IonText>
          </TItem>
        </IonList>
      ) : (
        <TItem>No Client Notes</TItem>
      )}
    </TExpandableItemGroup>
  );
};

export default LeadNotesModal;
