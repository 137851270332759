import React, { useState } from 'react';
import { TItemProps, default as TItem } from './TItem';
import {
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonIcon,
} from '@ionic/react';
import css from 'classnames';
import '../styles/components/TDateTimeItem.scss';
import moment from 'moment';
import { time } from 'ionicons/icons';

export interface TDateTimeItemProps extends TItemProps {
  value?: HTMLIonDatetimeElement['value'];
  displayFormat?: any;
  disabled?: boolean;
  fromDisabled?: boolean;
  toDisabled?: boolean;
  pickerOptions?: any;
  placeholder?: string;
  min?: HTMLIonDatetimeElement['min'];
  max?: HTMLIonDatetimeElement['max'];
  fromMin?: HTMLIonDatetimeElement['min'];
  fromMax?: HTMLIonDatetimeElement['max'];
  toMin?: HTMLIonDatetimeElement['min'];
  toMax?: HTMLIonDatetimeElement['max'];
  range?: boolean;
  fromPickerOptions?: any;
  toPickerOptions?: any;
  onIonChange?: (e: CustomEvent) => any;
  onFromIonChange?: (e: CustomEvent) => any;
  onToIonChange?: (e: CustomEvent) => any;
  fromValue?: string;
  toValue?: string;
  noIcon?: boolean;
}
function splitDisplayFormat(format: string) {
  const timeIndex =
    format.indexOf('h') !== -1 ? format.indexOf('h') : format.indexOf('H');
  const timePart = format.slice(timeIndex);
  const datePart = format.slice(0, timeIndex);
  return [timePart, datePart];
}
const TDateTimeItem: React.FC<TDateTimeItemProps> = ({
  value,
  children,
  displayFormat = 'MMM DD, YYYY',
  pickerOptions,
  disabled,
  min,
  max,
  placeholder = '',
  range,
  onIonChange,
  fromPickerOptions,
  toPickerOptions,
  onFromIonChange,
  onToIonChange,
  fromValue,
  toValue,
  toMin,
  toMax,
  toDisabled,
  fromMin,
  fromMax,
  fromDisabled,
  className,
  label,
  ...rest
}) => {
  const presentation = displayFormat.includes('hh') ? 'date-time' : 'date';
  const [timeDisplayFormat, dateDisplayFormat] =
    splitDisplayFormat(displayFormat);
  const [dtDateDisplay, setDtDateDisplay] = useState<string>(
    value ? moment(value).format(dateDisplayFormat) : 'Date'
  );
  const [dtTimeDisplay, setDtTimeDisplay] = useState<string>(
    value ? moment(value).format(timeDisplayFormat) : 'Time'
  );
  const [fromDisplay, setFromDisplay] = useState<string>(
    fromValue ? moment(fromValue).format(displayFormat) : 'Since'
  );
  const [toDisplay, setToDisplay] = useState<string>(
    toValue ? moment(toValue).format(displayFormat) : 'Until'
  );

  if (range) {
    const from = {
      displayFormat,
      min: fromMin,
      max: fromMax,
      disabled: fromDisabled,
      pickerOptions: fromPickerOptions,
      value: fromValue,
      onIonChange: (e: any) => {
        if (e.detail.value) {
          setFromDisplay(moment(e.detail.value).format(displayFormat));
          onFromIonChange?.(e);
        }
      },
    };

    const to = {
      displayFormat,
      min: toMin,
      max: toMax,
      disabled: toDisabled,
      pickerOptions: toPickerOptions,
      value: toValue,
      onIonChange: (e: any) => {
        if (e.detail.value) {
          setToDisplay(moment(e.detail.value).format(displayFormat));
          onToIonChange?.(e);
        }
      },
    };

    return (
      <TItem
        className={css('t-datetime-item', { range }, className)}
        label={label}
        {...rest}
      >
        {!rest.icon && (
          <IonIcon className='ion-hide-md-down' slot='start' icon={time} />
        )}
        <IonDatetimeButton datetime={`${label}-from`} slot='end'>
          <span slot='date-target'>{fromDisplay}</span>
        </IonDatetimeButton>
        <IonModal keepContentsMounted={true}>
          <IonDatetime
            mode="md"
            showDefaultButtons={true}
            presentation={presentation}
            id={`${label}-from`}
            {...from}
          />
        </IonModal>
        <span slot='end' className='ion-hide-md-down'>
          -
        </span>
        <IonDatetimeButton slot='end' datetime={`${label}-to`}>
          <span slot='date-target'>{toDisplay}</span>
        </IonDatetimeButton>
        <IonModal keepContentsMounted={true}>
          <IonDatetime
            mode="md"
            showDefaultButtons={true}
            presentation={presentation}
            id={`${label}-to`}
            {...to}
          />
        </IonModal>
      </TItem>
    );
  }

  const dt = {
    displayFormat,
    min,
    max,
    disabled,
    pickerOptions,
    value,
    onIonChange: (e: any) => {
      if (e.detail.value) {
        setDtDateDisplay(moment(e.detail.value).format(dateDisplayFormat));
        setDtTimeDisplay(moment(e.detail.value).format(timeDisplayFormat));
        onIonChange?.(e);
      }
    },
    placeholder,
  };

  return (
    <TItem label={label} {...rest}>
      <IonDatetimeButton datetime={`${label}-dt`} slot='end'>
        <span slot='date-target'>{dtDateDisplay}</span>
        {presentation?.includes('time') && (
          <span slot='time-target'>{dtTimeDisplay}</span>
        )}
      </IonDatetimeButton>
      <IonModal keepContentsMounted={true}>
        <IonDatetime
          mode="md"
          showDefaultButtons={true}
          presentation={presentation}
          id={`${label}-dt`}
          className='t-datetime'
          {...dt}
        />
      </IonModal>
    </TItem>
  );
};

export default TDateTimeItem;
