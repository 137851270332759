import React, {
  useEffect,
  useContext,
  useCallback,
  useState,
  useRef,
  useMemo,
} from 'react';
import {
  ChatBubble,
  TInfiniteScrollPage,
  EmojiPicker,
  ChatHeader,
  ChatFooter,
  ChatMentionsHelper,
  ChatProfileTooltip,
  LeadDuplicates,
  LeadTypingUsers,
} from '../components';
import {
  IonItemDivider,
  IonIcon,
  IonButton,
  useIonViewDidLeave,
} from '@ionic/react';
import {
  leadsService,
  pushNotifications,
  inboundLeadsService,
  forcedTrainingSessionService,
} from '../services';
import moment from 'moment';
import {
  util,
  appNotification,
  userDefaults,
  native,
  http,
  sentry,
} from '../core';
import { AppContext } from '../context/AppContext';
import { LeadContext, scrollToBottom } from '../context/LeadContext';
import { useLiveDBRef, useRequiresClockIn, useOnClickOutside } from '../hooks';
import {
  FacebookAdPreviewModal,
  PauseModal,
  MediaPreviewModal,
  QuickRepliesMdoal,
} from '../components/modals';
import { call } from 'ionicons/icons';
import { NotificationsContext } from '../context/NotificationsContext';
import {
  ChatMessageBoxContext,
  appendMessageText,
  setMessageFocus,
} from '../context/ChatMessageBoxContext';
import { useRouter } from '../hooks/';
import LeadAiReplies from '../components/LeadAiReplies';

export function isRecentMessage(msg: any) {
  return Date.now() - new Date(msg.created).getTime() < 60 * 60 * 1000;
}

export function isMessageCorrectLead(msg: any, lead: any) {
  return msg.lead === lead?.id;
}

export function messagePassesAdminCheck(msg: any, user: any) {
  return !msg.admins_only || user.is_admin;
}

export function messagePassesInternalCheck(msg: any, user: any) {
  return !msg.internal_only || user.is_staff;
}

export function shouldAppendMessage(msg: any, lead: any, user: any) {
  //message is less than 1hr old. This is to not put in any messages that get
  //left in firebase
  const isRecent = isRecentMessage(msg);
  const isCorrectLead = isMessageCorrectLead(msg, lead);
  const passesAdminCheck = messagePassesAdminCheck(msg, user);
  const passesInternalCheck = messagePassesInternalCheck(msg, user);

  return isCorrectLead && passesAdminCheck && passesInternalCheck && isRecent;
}

export function getMessageLiveURL(lead: any) {
  return !!lead
    ? `/text-messages/conversation/${lead?.client}/${lead?.id}/`
    : null;
}

const TextMessageConversationContent: React.FC = () => {
  const router = useRouter();
  const emojiRef = useRef<any>();
  const [openedAt, setOpenedAt] = useState<any>();
  const { dispatch: notifDispatch } = useContext(NotificationsContext);
  const { state: appState, dispatch: appDispatch } = useContext(AppContext);
  const { user, keyboardOpen } = appState;
  const loadingLead = useRef<any>();
  const leadContext = useContext(LeadContext);

  useEffect(() => {
      const fetchForcedTrainingSession = async () => {
          const { forcedTrainingSessions } = await forcedTrainingSessionService.load();
          appDispatch({ type: 'set', value: { forcedTrainingSessions } });
      };
      fetchForcedTrainingSession();
  }, [router.query.leadId, appDispatch]);

  const {
    dispatch: cbDispatch,
    state: cbState,
    onFileSelected,
  } = useContext(ChatMessageBoxContext);

  const {
    state,
    showAssignLeadDialog,
    showOptinLeadDialog,
    canViewLead,
    callLead,
    dispatch,
    isInbound,
    isNeedsCall,
  } = leadContext;
  const [userHasScrolled, setUserHasScrolled] = useState(false);

  useRequiresClockIn();

  const onNewMessage = useCallback(
    (msg: any) => {
      const message = JSON.parse(msg);
      const shouldAppend = shouldAppendMessage(message, state.lead, user);

      if (shouldAppend) {
        dispatch({
          type: 'newMessages',
          value: [message],
        });

        util.delay(() => scrollToBottom(), 500);
      }
    },
    [dispatch, user, state.lead]
  );

  const messagesLiveUrl = useMemo(
    () => getMessageLiveURL(state.lead),
    [state.lead]
  );

  useLiveDBRef(messagesLiveUrl, onNewMessage, !state.loading, 'child_added');

  const loadInfo = async () => {
    try {
      const { clientId, leadId } = router.query;

      /*Check to make sure that pathname and match url are the same
      the hook changes on the router, but the old params are in there.*/
      if (
        !leadId ||
        !router.location.pathname.match(/\/conversation\//i) ||
        router.pathname !== router.match.url ||
        (leadId === loadingLead.current?.leadId &&
          clientId === loadingLead.current?.clientId)
      ) {
        return;
      }
      loadingLead.current = { clientId, leadId };
      let internalMessages, notesOnly, callsOnly, emailsOnly;
      switch (state.internalMessageFilter) {
        case 'notesOnly':
          notesOnly = true;
          break;
        case 'internalOnly':
          internalMessages = true;
          break;
        case 'callsOnly':
          callsOnly = true;
          break;
        case 'emailsOnly':
          emailsOnly = true;
          break;
        case 'excludeInternal':
          internalMessages = false;
          break;
      }

      let [
        lead,
        thread,
        surveys,
        clientUsers = appState.clientUsers,
        clientGroups = appState.clientGroups,
        clientHours = appState.clientHours,
        clientMentionUsers = appState.clientMentionUsers,
        leadClient = appState.selectedClient,
      ] = await leadsService.loadConversationData(
        clientId,
        leadId,
        appState.selectedClientId,
        internalMessages,
        notesOnly,
        callsOnly,
        emailsOnly
      );
      const now = moment();
      const dayName = now.format('dddd');
      const hoursToday = clientHours?.find((it: any) => it.weekday === dayName);
      lead.client_hours = '';
      lead.client_hours_today = hoursToday
        ? `${moment(hoursToday.from_hour, 'HH:mm:ss').format(
            'h:mma'
          )} - ${moment(hoursToday.to_hour, 'HH:mm:ss').format('h:mma')}`
        : '';
      clientHours?.forEach((it: any, index: number) => {
        lead.client_hours += `${it.weekday} ${moment(
          it.from_hour,
          'HH:mm:ss'
        ).format('h:mma')} - ${moment(it.to_hour, 'HH:mm:ss').format(
          'h:mma'
        )}\n`;
      });
      leadsService.checkForPauseResets(lead, thread);

      const seenHeaderTooltip = await userDefaults.getValue(
        'seen-chat-header-tooltip'
      );

      //Don't wait for it to load
      pushNotifications.markAllNotifications('read', leadId);
      notifDispatch({
        type: 'markLeadRead',
        value: leadId,
      });

      dispatch({
        type: 'set',
        value: {
          clientId,
          leadId,
          lead,
          thread: thread.results.reverse(),
          next: thread.next,
          error: false,
          assignDialogDismissed: false,
          surveys: surveys?.results,
          clientHours,
          clientUsers,
          leadClient,
          nextLead: null,
          showHeaderTooltip: !seenHeaderTooltip && window.innerWidth < 992,
          loading: false,
        },
      });

      cbDispatch({
        type: 'set',
        value: {
          selectedMentionIndex: 0,
          emojiOpen: false,
          showMentionsPopup: false,
          showQuickReplies: false,
          showAllTools: false,
          internalOnly: false,
          allNotificationGroups: [
            ...clientMentionUsers.map((it: any) => ({
              id: it.display_name,
              key: `user_${it.display_name}`,
              name: it.label,
              username: it.display_name,
            })),
            ...clientGroups.map((it: any) => ({
              id: it.id,
              key: `group_${it.id}`,
              name: it.name,
              username: it.name,
            })),
          ],
        },
      });

      if (!native.isNative) {
        util.delay(setMessageFocus, 500);
      }

      //HACK: list adjusts based on variable height content.
      //Need to _actually_ fix
      util.delay(scrollToBottom, 150);
      util.delay(scrollToBottom, 750);
    } catch (e: any) {
      if (e.response?.status === 404) {
        router.replace('/text-messages/', {});
        return appNotification.toast(
          'This lead is assigned to someone else.',
          'No Access'
        );
      } else {
        console.error(e);
        dispatch({
          type: 'set',
          value: {
            error: true,
            loading: false,
          },
        });
      }
    } finally {
      loadingLead.current = null;
    }
  };

  const onLeadChange = () => {
    setUserHasScrolled(false);
    loadInfo();
  };
  // eslint-disable-next-line
  useEffect(onLeadChange, [router, state.internalMessageFilter]);

  const loadNextThread = useCallback(async () => {
    try {
      const res = await leadsService.next(state.next);
      dispatch({
        type: 'appendThread',
        value: res.results,
      });

      dispatch({
        type: 'set',
        value: {
          next: res.next,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }, [dispatch, state.next]);

  useOnClickOutside(emojiRef, () =>
    cbDispatch({
      type: 'set',
      value: {
        emojiOpen: false,
      },
    })
  );

  useEffect(() => {
    if (!canViewLead(user)) {
      appNotification.toast(
        'This lead is already assigned to someone else.',
        'Lead is Assigned'
      );
      window.location.href = '/text-messages/';
      // using router.replace is causing the component to re-render infinitely
      //router.replace('/text-messages/', {});
    }
  }, [user, router, canViewLead]);

  useEffect(() => showAssignLeadDialog(user), [user, showAssignLeadDialog]);
  useEffect(() => showOptinLeadDialog(user), [user, showOptinLeadDialog]);

  const workingLeads = useLiveDBRef(
    `/working-leads/${user.id}/`,
    (val: any, ref: any) => {
      const leadId = router.query.leadId;
      if (
        leadId &&
        val?.lid &&
        val?.lid?.toString() !== leadId.toString() &&
        val.ts > (openedAt ?? Date.now())
      ) {
        sentry.addBreadcrumb({
          message: `Working Lead: ${val?.lid} ${leadId} ${val.ts} ${openedAt}`,
        });
        sentry.capture(new Error('Opened lead in another session.'));
        ref.off();
        appNotification.toast(
          'Different lead opened in another session.',
          'Error'
        );
        router.history.goBack();
      }
    },
    isInbound || isNeedsCall
  );

  useEffect(() => {
    util.delay(scrollToBottom, 200);
  }, [keyboardOpen]);

  useEffect(() => {
    if (router.query.leadId && (isInbound || isNeedsCall)) {
      const open = Date.now();
      setOpenedAt(open);
      workingLeads.current?.set({
        lid: router.query.leadId,
        ts: open,
      });
    }
  }, [isInbound, isNeedsCall, router, workingLeads]);

  useIonViewDidLeave(() => {
    const leadId = state.lead?.id;
    if ((isInbound || isNeedsCall) && leadId) {
      inboundLeadsService.removeServedLead(leadId);
    }
    setUserHasScrolled(false);
    dispatch({ type: 'clear' });
  });

  const isDropSupported = (e: any) =>
    leadsService.isSupportedFileType(
      e.dataTransfer.items[0].type,
      cbState.isEmail
    );

  const onDrop = (e: any) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      return onFileSelected(file);
    }

    const item = e.dataTransfer.items[0];
    if (item) {
      item?.getAsString(async (url: string) => {
        const file = await http.uriToFile(url);
        onFileSelected(file);
      });
    }
  };

  const userScrolled = () => setUserHasScrolled(!state.loading);

  const renderChatBubble = (it: any) => {
    if (!state.thread) {
      return null;
    }

    const index = state.thread.indexOf(it);
    const previousItem = index > 0 ? state.thread[index - 1] : null;
    let heading: any = null;
    const sender_user_id = it?.user || null;
    let {
      id,
      message,
      sender_name,
      sent_by_ninja,
      sent_by_1099,
      sms_type,
      smsmedia_set,
      created,
      media,
      content_type,
      from_followup,
      read_at,
      gif_url,
      video_thumb,
      text_ninja,
      is_email,
      twilio_status,
      outbound_email_status,
      iframe_tag,
      call,
      resetPause,
      language,
      english_translation,
      sms_blast,
      video_meeting_status,
    } = it;

    const format = 'dddd, MMMM D, YYYY';
    const mc = moment(created).format(format);
    const pc = previousItem ? moment(previousItem.created).format(format) : '';

    const mediaSet = [].concat(smsmedia_set) as any[];

    if (media && content_type) {
      mediaSet.push({
        id,
        media: media,
        content_type,
      });
    }

    if (mc !== pc) {
      heading = (
        <IonItemDivider key={mc} sticky>
          {mc}
        </IonItemDivider>
      );
    }

    const outgoing = ['outbound', 'system'].indexOf(sms_type) > -1;

    if (from_followup) {
      sender_name = 'TECOBI Automations';
    }

    if (!sender_name && !outgoing) {
      sender_name = `${state.lead?.first_name} ${state.lead?.last_name}`;
    }

    const bubble = (
      <ChatBubble
        id={id}
        key={id}
        senderId={sender_user_id}
        isEmail={is_email}
        mediaSet={mediaSet}
        message={message}
        sender={sender_name}
        smsBlast={sms_blast}
        ninja={sent_by_ninja}
        senderIs1099={sent_by_1099}
        read={!!read_at}
        adPreview={iframe_tag}
        call={call}
        videoThumb={video_thumb}
        gif={gif_url}
        translation={english_translation}
        videoMeetingStatus={video_meeting_status}
        language={language}
        dateSent={util.formatMessageDate(created)}
        outgoing={outgoing}
        yellow={!!text_ninja}
        beige={
          !text_ninja &&
          is_email
        }
        orange={
          !!text_ninja &&
          (message.includes('needs a call') ||
            message.includes('Needs call set to true'))
        }
        red={!!text_ninja && message.includes('**Message not sent')}
        internal={!!text_ninja}
        resetPause={resetPause}
        twilioStatus={twilio_status}
        outboundEmailStatus={user.is_staff ? outbound_email_status : undefined}
      />
    );
    return heading ? [heading, bubble] : bubble;
  };

  return (
    <TInfiniteScrollPage
      contentId='conversationContent'
      loading={state.loading}
      className='text-message-thread'
      onUserScroll={userScrolled}
      toolbar={<LeadDuplicates onMerged={loadInfo} />}
      customHeaderContent={<ChatHeader />}
      customFooterContent={<ChatFooter />}
      headerTool={
        <IonButton onClick={callLead} fill='clear' slot='end'>
          <IonIcon slot='icon-only' icon={call} />
        </IonButton>
      }
      fab={<LeadAiReplies />}
      items={state.thread ?? []}
      position='top'
      hasNext={!state.loading && !!state.next}
      error={state.error}
      isDropSupported={isDropSupported}
      onDrop={onDrop}
      contentChildren={
        state.lead?.id && (
          <LeadTypingUsers
            leadId={state.lead?.id}
            leadFullName={`${state.lead?.first_name} ${state.lead?.last_name}`}
            clientId={state.lead?.client}
            clientName={state.lead?.client_name}
            userHasScrolled={userHasScrolled}
          />
        )
      }
      onLoadNextPage={loadNextThread}
      renderItem={renderChatBubble}
    >
      {!native.isNative && (
        <EmojiPicker
          ref={emojiRef}
          isOpen={cbState.emojiOpen}
          onSelected={appendMessageText}
        />
      )}
      {cbState.showMentionsPopup && <ChatMentionsHelper />}
      {!state.loading && state.showHeaderTooltip && <ChatProfileTooltip />}
      {state.showFacebookAd && (
        <FacebookAdPreviewModal
          isOpen={true}
          onDidDismiss={() =>
            dispatch({
              type: 'set',
              value: { showFacebookAd: false },
            })
          }
        />
      )}
      {state.showPauseModal && (
        <PauseModal
          isOpen={true}
          onDidDismiss={() =>
            dispatch({
              type: 'set',
              value: { showPauseModal: false },
            })
          }
        />
      )}

      {state.mediaPreview && (
        <MediaPreviewModal
          isOpen={true}
          onDidDismiss={() =>
            dispatch({ type: 'set', value: { mediaPreview: null } })
          }
        />
      )}

      {cbState.showQuickReplies && (
        <QuickRepliesMdoal
          isOpen={true}
          onDidDismiss={() =>
            cbDispatch({
              type: 'set',
              value: { showQuickReplies: false },
            })
          }
        />
      )}
    </TInfiniteScrollPage>
  );
};

export default TextMessageConversationContent;
