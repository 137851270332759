import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction
} from 'react';
import { IonPopover, IonSearchbar, IonList, IonItem, IonContent } from '@ionic/react';
import { isPlatform } from '@ionic/core';
import '../styles/components/PopoverSelector.scss';

export interface PopoverSelectorProps {
  isOpen: boolean;
  items: object[];
  filter: (item: any) => string;
  idField: string;
  renderLabel: (item: any) => string;

  onSelectionMade: (obj: object) => void;
  onDidDismiss: () => void;
  placeholder?: string;
}

const  PopoverSelector: ForwardRefRenderFunction<
unknown,
PopoverSelectorProps
> = (
  props: PopoverSelectorProps,
  ref: any
) => {
  const {
    onDidDismiss,
    isOpen,
    items,
    onSelectionMade,
    renderLabel,
    placeholder
  } = props;
  const [searching, setSearching] = useState(false);
  const [filtered, setFiltered] = useState<object[]>([]);
  const searchBar = useRef<any>();

  useImperativeHandle(ref, () => ({
    get searchBar() {
      return searchBar.current;
    }
  }));

  const search = ({ detail }: any) => {
    const { value } = detail;
    if (!!value) {
      setFiltered(
        props.items.filter(it =>
          props.filter(it).match(new RegExp(detail.value, 'gi'))
        )
      );
      setSearching(true);
    } else {
      setFiltered([]);
      setSearching(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      if (searchBar.current) {
        searchBar.current.value = '';
      }

      setFiltered(props.items);
    }
  }, [isOpen, searchBar, props.items]);

  return (
    <IonPopover
      mode={isPlatform('android') ? 'md' : 'ios'}
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      className="popover-selector"
    >
      <IonSearchbar
        className="no-margin no padding"
        mode={isPlatform('android') ? 'md' : 'ios'}
        onIonChange={search}
        placeholder={placeholder || 'Search'}
        ref={searchBar}
      />
      <IonContent>
        <IonList>
          {(searching ? filtered : items).map((it: any) => (
            <IonItem
              key={it[props.idField]}
              lines="full"
              className="pointer"
              onClick={e => {
                e.stopPropagation();
                onSelectionMade(it);
              }}
            >
              {renderLabel(it)}
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPopover>
  );
};

export default forwardRef(PopoverSelector);
