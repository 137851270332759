import React, { useState, useEffect, useContext } from "react";
import TModal from "./TModal";
import { IonSearchbar } from "@ionic/react";
import TChart from "../TChart";
import { AppContext } from "../../context/AppContext";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { isPlatform } from "@ionic/core";
import { util } from "../../core";
import DataTable from "react-data-table-component";

const customStyles: any = {
  rows: {
    style: {
      cursor: "pointer",
    },
  },
};

export interface LeadListModalProps {
  isOpen?: boolean;
  leads: any;
  leadsCount?: number;
  title: string;
  renderBarChart?: boolean;
  onDidDismiss: () => any;
}

const LeadListModal: React.FC<LeadListModalProps> = ({
  isOpen,
  leads,
  leadsCount,
  title,
  renderBarChart,
  onDidDismiss,
}) => {
  const countPerPage = 5;
  const { state } = useContext(AppContext);
  const history = useHistory();
  // Used for is_full_service hide avg response time chart
  const { user } = state;
  const [columns, setColumns] = useState([]);
  const [isFullName, setIsFullName] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(leads);
  const handleChange = (event: any) => {
    setSearchTerm(event.target.value);
  };
  util.createTecobiDarkDataTableTheme();
  useEffect(() => {
    const results = leads.filter((lead: any) => {
      const fullName = isFullName
        ? lead?.full_name
        : `${lead?.first_name} ${lead?.last_name}`;
      return fullName.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setSearchResults(results);
  }, [searchTerm, leads, isFullName]);

  useEffect(() => {
    setSearchResults(leads);
    const isFullName = !!leads?.[0]?.full_name;
    setIsFullName(isFullName);
    const cols = isFullName
      ? [
          {
            name: "Name",
            selector: "full_name",
          },
          {
            name: "Date",
            selector: "event_time",
            format: (row: any) => moment(row.event_time).format("lll"),
          },
        ]
      : ([
          {
            name: "First",
            selector: "first_name",
          },
          {
            name: "Last",
            selector: "last_name",
          },
          {
            name: "Date",
            selector: "event_time",
            format: (row: any) => moment(row.event_time).format("lll"),
          },
        ] as any);
    // dynamic columns based on full_name vs first/last
    setColumns(cols);
  }, [leads]);

  const formatChartMonth = (isoString: string) => {
    return moment(isoString).format("YY/MM");
  };

  const chartLabels: Array<string> = [];
  const chartData: Array<number> = [];
  const unorderedChartObj: any = {};
  const chartObj: any = {};
  if (renderBarChart) {
    leads.forEach((it: any) => {
      const monthKey = formatChartMonth(it.event_time);
      if (monthKey in unorderedChartObj) {
        unorderedChartObj[monthKey]++;
      } else {
        unorderedChartObj[monthKey] = 1;
      }
    });

    Object.keys(unorderedChartObj)
      .sort()
      .forEach((key) => {
        const newKeyPcs = key.split("/");
        const newKey = `${newKeyPcs[1]}/${newKeyPcs[0]}`;
        chartObj[newKey] = unorderedChartObj[key];
      });

    for (const k in chartObj) {
      chartLabels.push(k);
      chartData.push(chartObj[k]);
    }
  }

  return (
    <TModal isOpen={isOpen} title={title} onDidDismiss={onDidDismiss}>
      {renderBarChart && (
        <TChart
          type="bar"
          data={chartData}
          labels={chartLabels}
          options={{
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1,
                  },
                },
              ],
            },
          }}
        />
      )}
      <IonSearchbar
        className="no-margin no padding"
        mode={isPlatform("android") ? "md" : "ios"}
        onIonChange={handleChange}
        value={searchTerm}
        placeholder="Filter Leads"
      />
      <DataTable
        noHeader={true}
        columns={columns}
        customStyles={customStyles}
        data={searchResults}
        theme={user.mobile_theme === "dark" ? "tecobi-dark" : "default"}
        highlightOnHover
        pagination={searchResults?.length > countPerPage}
        paginationTotalRows={searchResults?.length}
        paginationPerPage={countPerPage}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        onRowClicked={(row: any, e: any) => {
          history.push(
            `/text-messages/conversation/${row.client ?? row.client_id}/${
              row.id ?? row.lead_id
            }/`
          );
          onDidDismiss();
        }}
      />
    </TModal>
  );
};

export default LeadListModal;
