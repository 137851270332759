import React, { useContext, useMemo } from 'react';
import { PersonalizedLeadContext } from '../context/PersonalizedLeadContext';
import moment from 'moment';
import { util, browser, calendar } from '../core';
import { TMap } from '.';
import TItem from './TItem';
import { car, calendarOutline } from 'ionicons/icons';
import HapticButton from './HapticButton';

const PersonalizedLeadFeaturedVideo: React.FC = () => {
  const { state } = useContext(PersonalizedLeadContext);
  const { lead, token } = state;
  const { client } = lead ?? {};
  const appointment = useMemo(() => state.featureData ?? {}, [state.featureData]);
  const ad = useMemo(
    () =>
      moment(appointment?.appointment_time).tz(
        util.formattedTimezone(lead?.client?.timezone)
      ),
    [appointment, lead]
  );

  if (state.feature !== 'appointment') {
    return null;
  }

  let coords: any;

  if (lead?.client?.lat) {
    coords = {
      lat: parseFloat(lead?.client?.lat),
      lng: parseFloat(lead?.client?.lng)
    };
  }

  const downloadICS = async () => {
    await calendar.downloadICS(
      {
        title: 'Purchase New Vehicle',
        duration: { hours: 1, minutes: 0 },
        geo: client?.lat ? { lat: client.lat, lon: client.lng } : undefined,
        start: ad.toDate(),
        status: 'CONFIRMED',
        url: `https://app.tecobi.com/d/${token}/appointment/${appointment?.id}/`,
        location: `${client?.address} ${client?.address_2} ${client?.city}, ${client?.state} ${client?.postal_code}`
      },
      `${client?.name}-${appointment?.appointment_time}.ics`
    );
  };

  return (
    <div className="featured-appointment">
      <h2>Your Appointment</h2>
      <TItem label="When" labelPosition="stacked" lines="full">
        <p>
          {ad.format('dddd, MMMM D, YYYY')} at {ad.format('h:mma')}
        </p>
      </TItem>
      <TItem label="Where" labelPosition="stacked" lines="full">
        <p>
          {lead?.client?.name}
          <br />
          {lead?.client?.address}
          {lead?.client?.address_2 && <br />}
          {lead?.client?.address_2}
          <br />
          {lead?.client?.city}, {lead?.client?.state}{' '}
          {lead?.client?.postal_code}
        </p>
      </TItem>
      {coords && (
        <div
          style={{
            position: 'relative',
            height: 200
          }}
        >
          <TMap coords={coords} />
        </div>
      )}
      <div style={{ padding: '10px 0' }}>
        <HapticButton
          color="secondary"
          icon={car}
          onClick={() =>
            browser.open(
              coords?.lat
                ? `https://www.google.com/maps/dir/?api=1&origin=&destination=${coords?.lat},${coords?.lng}`
                : `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    `${lead?.client?.name}, ${lead?.client?.city}, ${lead?.client?.state}`
                  )}`
            )
          }
        >
          Get Directions
        </HapticButton>
        <HapticButton
          color="secondary"
          icon={calendarOutline}
          onClick={downloadICS}
        >
          Add to Calendar
        </HapticButton>
      </div>
    </div>
  );
};

export default PersonalizedLeadFeaturedVideo;
