import PagedService from './paged-service';
import { http } from '../core';
import qs from 'querystring';

class SmsBillingService extends PagedService {
  async list(
    clientId: number,
    since: Date,
    until: Date,
    smsType: String = '',
    twilioNumber: Number | String = ''
  ) {
    try {
      const params = {
        created__gte: since.toISOString(),
        created__lte: until.toISOString(),
        twilio_message_id__isnull: false,
        call__isnull: true,
        text_ninja: false,
        page_size: 1,
      } as any;
      if (smsType) {
        params['sms_type'] = smsType;
      }
      if (twilioNumber) {
        params['twilio_number'] = twilioNumber;
      }
      const query = qs.stringify(params);
      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/smsmessage-billing-counts/?${query}`,
        type: `sms-billing-${clientId}-${smsType || 'all'}`,
        exclusive: true,
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

const smsBillingService = new SmsBillingService();
export default smsBillingService;
