import React from 'react';
import { uiUtil } from '../../core';
import TTextItem from '../TTextItem';
import { IonList } from '@ionic/react';
import ButtonGrid from '../ButtonGrid';
import HapticButton from '../HapticButton';
import TModal from './TModal';

export interface SurveyModalProps {
  survey: any;
  onWillDismiss?: () => any;
  showLeadButton?: boolean;
}

const SurveyModal: React.FC<SurveyModalProps> = ({
  survey,
  onWillDismiss,
  showLeadButton
}) => {
  return (
    <TModal
      isOpen={true}
      title={`${survey.full_name}`}
      onWillDismiss={onWillDismiss}
      footer={
        showLeadButton ? (
          <ButtonGrid>
            <HapticButton
              routerLink={`/text-messages/conversation/${survey.client}/${survey.lead}/`}
              onClick={onWillDismiss}
              color="secondary"
              expand="block"
              fill="solid"
            >
              View Lead
            </HapticButton>
          </ButtonGrid>
        ) : (
          undefined
        )
      }
    >
      <IonList>
        {survey.answer_set?.map((it: any) => (
          <TTextItem
            key={it.question}
            label={it.question_text}
            labelPosition="stacked"
            text={uiUtil.renderRating(it.body)}
          />
        ))}
        {survey.comment && (
          <TTextItem
            label="Comments"
            labelPosition="stacked"
            text={survey.comment ?? ''}
          />
        )}
      </IonList>
    </TModal>
  );
};

export default SurveyModal;
