import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import tecobiIcon from '../icons/tecobi_icon.svg';
import {
  IonItem,
  IonText,
  IonIcon,
  IonLabel,
  IonSkeletonText,
  IonFab,
  IonFabButton,
  IonButton,
  IonPopover,
  IonContent,
  IonCardHeader,
  IonCardTitle,
  IonList,
} from '@ionic/react';
import { aiRepliesService } from '../services';
import { LeadContext } from '../context/LeadContext';
import '../styles/components/LeadAiReplies.scss';
import { chatbubbleEllipsesOutline, closeOutline } from 'ionicons/icons';
import {
  ChatMessageBoxContext,
  clearMessage,
} from '../context/ChatMessageBoxContext';

const LeadAiReplies = () => {
  const { state } = useContext(LeadContext);
  const { lead } = state;
  const { appendMessage } = useContext(ChatMessageBoxContext);
  const [replies, setReplies] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const popoverRef = useRef<any>(null);
  const append = (c: string) => {
    clearMessage();
    appendMessage(c);
    popoverRef?.current?.dismiss();
  };

  const lookup = useCallback(async () => {
    if (lead) {
      const { replies } = await aiRepliesService.list(lead.client, lead.id);
      setReplies(replies);
      setLoading(false);
    }
  }, [lead]);

  useEffect(() => {
    setReplies([]);
    setLoading(true);
  }, [lead?.id]);

  return !lead?.client_use_ai_replies ? null : (
    <>
      <IonFab
        slot='fixed'
        onClick={() => lookup()}
        className='t-fab t-ai-fab'
        vertical='bottom'
        horizontal='end'
      >
        <IonFabButton
          id='ai-click-trigger'
          size='small'
          color={'success'}
        >
          <IonIcon className='ai-icon' icon={tecobiIcon} />
        </IonFabButton>
      </IonFab>
      <IonPopover
        arrow={false}
        ref={popoverRef}
        trigger='ai-click-trigger'
        side='top'
        alignment='end'
        triggerAction='click'
        className='t-ai-replies-popover'
      >
        <IonCardHeader color='warning'>
          <IonButton
            className='ai-close'
            onClick={() => popoverRef?.current?.dismiss()}
            fill='clear'
            style={{ float: 'right' }}
          >
            <IonIcon icon={closeOutline} />
          </IonButton>
          <IonCardTitle className='ai-title'>AI Replies</IonCardTitle>
        </IonCardHeader>
        <IonContent>
          <IonList>
            {loading &&
              Array.from(Array(5).keys()).map((it: any, i: number) => (
                <IonItem key={`ai-skeleton-${i}`}>
                  <IonLabel>
                    <IonSkeletonText animated style={{ width: '85%' }} />
                    <IonSkeletonText animated style={{ width: '80%' }} />
                    <IonSkeletonText animated style={{ width: '75%' }} />
                  </IonLabel>
                </IonItem>
              ))}
            {!loading && !replies?.length && <IonItem>Not available</IonItem>}
            {replies?.map((reply: string, index: number) => (
              <IonItem
                key={`ai-reply-${index}`}
                className='pointer'
                onClick={() => append(reply)}
              >
                <IonLabel className='ion-multiline ion-text-wrap'>
                  <IonText>{reply}</IonText>
                </IonLabel>
                <IonIcon icon={chatbubbleEllipsesOutline} slot='end' />
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      </IonPopover>
    </>
  );
};

export default LeadAiReplies;
