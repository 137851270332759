import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  isPlatform,
  IonItemGroup,
  IonItemDivider
} from '@ionic/react';
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback
} from 'react';
import { FeedbackModal } from './modals';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../context/AppContext';
import pushNotifications from '../services/push-notifications';
import { util, haptics } from '../core';
import ClientSelector from './ClientSelector';
import { AppPage } from '../pages';
import TAvatar from './TAvatar';
import { cog, arrowBack, time, chatbubble } from 'ionicons/icons';
import css from 'classnames';
import TMenuItem from './TMenuItem';
import useHammer from '../hooks/useHammer';
import { userService, clientService } from '../services';
import HapticButton from './HapticButton';

export interface MenuProps {
  pages: AppPage[];
}

const Menu: React.FunctionComponent<MenuProps> = ({ pages }) => {
  const menuContent = useRef<any>();
  const location = useLocation();
  const appContext = useContext(AppContext);
  const { dispatch } = appContext;
  const menu = useRef<any>();
  const selectedPage = util.getSelectedPage(location, pages);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [parentPage, setParentPage] = useState<AppPage | null | undefined>(
    util.getParentPage(selectedPage, pages)
  );

  const [submenu, setSubmenu] = useState<AppPage[] | null | undefined>(
    parentPage?.subpages
  );
  const [showSubmenu, setShowSubmenu] = useState(!!submenu);

  useEffect(() => {
    pushNotifications.loadUnread();
  }, []);

  useEffect(() => {
    util.setTitle(selectedPage?.title ?? '');
  }, [selectedPage]);

  const { user, isClockedIn } = appContext.state;
  const supportsClockIn =
    userService.canAccessInboundQueue(user) ||
    clientService.isForceClockin(appContext.state?.selectedClient) ||
    (userService.isRoundRobinEnabled(user) &&
      clientService.isRoundRobin(appContext.state?.selectedClient));
  const userAvatar = user?.profile_pic;
  const closeMenu = useCallback(() => {
    haptics.lightImpact();
    menu.current?.close?.();
  }, [menu]);

  const isSettingsOpen = location.pathname === '/settings/';

  const goBackToMainMenu = useCallback(() => {
    setShowSubmenu(false);
    menuContent.current?.scrollToTop();
    util.delay(() => {
      setSubmenu(null);
      setParentPage(null);
    }, 450);
  }, [menuContent]);

  const goToSubmenu = useCallback(
    (appPage: AppPage) => {
      setSubmenu(appPage.subpages);
      setShowSubmenu(true);
      setParentPage(appPage);
      menuContent.current?.scrollToTop();
    },
    [menuContent]
  );

  useHammer('main-menu', 'swiperight', (event: any) => {
    const { distance } = event;
    if (distance > 80 && submenu) {
      goBackToMainMenu();
    }
  });

  const updateClockedInStatus = async () => {
    const success = await userService.toggleClockIn(isClockedIn);
    if (success) {
      dispatch({ type: 'set', value: { isClockedIn: !isClockedIn } });
    }
  };

  return (
    <IonMenu
      ref={menu}
      contentId="main"
      menuId="main-menu"
      id="main-menu"
      type="overlay"
      swipeGesture={!isPlatform('mobileweb')}
    >
      <div
        style={{
          height: 'env(safe-area-inset-top)',
          background: 'var(--ion-item-background)'
        }}
      />
      <IonItem lines="full" detail={false} className="menu-avatar">
        <TAvatar
          src={userAvatar}
          alt={`${user?.first_name ?? ''} ${user?.last_name ?? ''}`}
          style={{
            margin: '10px 20px 10px 0',
            height: 35,
            width: 35
          }}
          slot="start"
        />
        <IonLabel className="ion-multiline">
          <strong>
            {user?.first_name} {user?.last_name}
          </strong>
          <span className="detail">{user?.email}</span>
          {supportsClockIn && (
            <HapticButton
              color={isClockedIn ? 'success' : 'danger'}
              className="pointer clocked-in-status"
              onClick={updateClockedInStatus}
              icon={time}
            >
              {isClockedIn ? 'Clocked in' : 'Clocked out'}
            </HapticButton>
          )}
        </IonLabel>
        <HapticButton
          onClick={() => {
            closeMenu();
            setShowFeedbackModal(true);
          }}
          color="priimary"
          icon={chatbubble}
        />
        <HapticButton
          routerLink="/settings/"
          routerDirection="none"
          onClick={closeMenu}
          color={isSettingsOpen ? 'success' : 'primary'}
          fill={isSettingsOpen ? 'solid' : 'clear'}
        >
          <IonIcon slot="icon-only" icon={cog} />
        </HapticButton>
      </IonItem>
      <IonContent
        ref={menuContent}
        forceOverscroll
        style={{
          '--background': 'var(--ion-item-background)'
        }}
      >
        <IonList
          style={{
            paddingTop: 0
          }}
          className={css('main-menu-items', { visible: !showSubmenu })}
        >
          {pages.map((appPage, index) => (
            <TMenuItem
              page={appPage}
              key={index}
              showSubmenu={goToSubmenu}
              isSelected={appPage === selectedPage}
            />
          ))}
        </IonList>
        <IonList
          style={{
            paddingTop: 0
          }}
          className={css('sub-menu-items', { visible: showSubmenu })}
        >
          <IonItemGroup>
            <IonItem
              className="ion-activatable pointer"
              onClick={goBackToMainMenu}
              lines="full"
              detail={false}
            >
              <IonIcon icon={arrowBack} slot="start" />
              <IonLabel>Back</IonLabel>
            </IonItem>
            <IonItemDivider mode="md" sticky>
              {parentPage?.title}
            </IonItemDivider>
          </IonItemGroup>
          {submenu?.map?.((appPage, index) => (
            <TMenuItem
              page={appPage}
              key={index}
              showSubmenu={goToSubmenu}
              isSelected={appPage === selectedPage}
            />
          ))}
        </IonList>
      </IonContent>

      <ClientSelector />
      <div
        style={{
          height: 'env(safe-area-inset-bottom, 5px)',
          background: 'var(--ion-item-background)'
        }}
      />
      {showFeedbackModal && (
        <FeedbackModal onDidDismiss={() => setShowFeedbackModal(false)} />
      )}
    </IonMenu>
  );
};

export default Menu;
