import React, { useState, useMemo } from 'react';
import {
  IonRefresher,
  IonRefresherContent,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonText,
  IonButton,
  IonProgressBar
} from '@ionic/react';
import TPage, { TPageProps } from './TPage';
import TLoading from './TLoading';
import css from 'classnames';
import { useKeyboardShortcuts } from '../hooks';
import { haptics, util, native } from '../core';
import { arrowDown } from 'ionicons/icons';

export interface TInfiniteScrollPageProps extends TPageProps {
  onRefresh?: () => Promise<any>;
  toolbar?: any;
  renderItem: (item: any, index?: number) => any;
  items: any[] | null;
  onLoadNextPage: (e?: any) => Promise<void> | any;
  zeroStateText?: string;
  hasNext: boolean;
  position?: 'top' | 'bottom' | undefined;
  className?: string;
  contentId?: string;
  listId?: string;
  children?: any;
  onIonScrollStart?: (e: any) => void;
  onIonScrollEnd?: (e: any) => void;
  onUserScroll?: () => void;
  onBodyClick?: () => void;
  fab?: JSX.Element;
  toolbarLoadingIndicator?: boolean | number;
  contentChildren?: any;
}

const TInfiniteScrollPage: React.FC<TInfiniteScrollPageProps> = props => {
  const {
    onRefresh,
    renderItem,
    onLoadNextPage,
    items,
    zeroStateText,
    hasNext,
    position,
    loading,
    error,
    onRetry,
    noFooter,
    noClientSelector,
    customFooterContent,
    customHeaderContent,
    toolbar,
    className,
    children,
    contentId,
    onIonScrollStart,
    onIonScrollEnd,
    onUserScroll,
    onDrop,
    isDropSupported,
    onBodyClick,
    fab,
    listId,
    headerTool,
    toolbarLoadingIndicator,
    contentChildren,
    loadingText,
    footerId,
    headerId,
    preContent,
    ...rest
  } = props;
  const [userRefreshing, setUserRefreshing] = useState(false);
  const [pullRefreshing, setPullRefreshing] = useState(false);
  const renderedItems = useMemo(
    () => !loading && !userRefreshing && items && items.map(renderItem),
    [loading, items, renderItem, userRefreshing]
  );
  const isEmpty = useMemo(
    () =>
      !loading &&
      !userRefreshing &&
      !pullRefreshing &&
      (!renderedItems || !renderedItems.length),
    [loading, renderedItems, userRefreshing, pullRefreshing]
  );

  const pageProps = {
    error,
    onRetry,
    noFooter,
    onDrop,
    isDropSupported,
    noClientSelector,
    customFooterContent,
    customHeaderContent,
    headerTool,
    headerId,
    footerId,
    preContent,
    noContent: true
  };

  useKeyboardShortcuts({
    'CmdOrCtrl+R': async (e: any) => {
      if (onRefresh) {
        e.preventDefault();
        e.stopPropagation();
        setUserRefreshing(true);
        await onRefresh();
        setUserRefreshing(false);
      }
    }
  });

  const isTopDirection = position === 'top';
  const infiniteScroller = (
    <IonInfiniteScroll
      disabled={!hasNext}
      position={position || 'bottom'}
      onIonInfinite={async (e: any) => {
        await onLoadNextPage();
        e.target.complete();
      }}
    >
      <IonInfiniteScrollContent />
    </IonInfiniteScroll>
  );

  const refresherProps = {} as any;
  if (native.isIOS) {
    refresherProps.pullingIcon = arrowDown;
  }

  return (
    <TPage {...pageProps}>
      {toolbar}
      <IonContent
        id={contentId ?? util.uuid()}
        onIonScrollStart={onIonScrollStart}
        onIonScrollEnd={onIonScrollEnd}
        onWheel={onUserScroll}
        onTouchMove={onUserScroll}
        className={className}
        onClick={onBodyClick}
      >
        {toolbarLoadingIndicator && (
          <IonProgressBar
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 1000
            }}
            color="success"
            value={
              typeof toolbarLoadingIndicator === 'number'
                ? toolbarLoadingIndicator
                : undefined
            }
            type={
              typeof toolbarLoadingIndicator === 'number'
                ? 'determinate'
                : 'indeterminate'
            }
          />
        )}
        {isTopDirection && infiniteScroller}
        <IonRefresher
          disabled={!onRefresh}
          slot="fixed"
          onIonRefresh={async e => {
            try {
              setPullRefreshing(true);
              haptics.lightImpact();
              await onRefresh?.();
              e.detail.complete();
              haptics.lightImpact();
            } finally {
              setPullRefreshing(false);
            }
          }}
        >
          <IonRefresherContent {...refresherProps} />
        </IonRefresher>
        <IonList
          id={listId ?? util.uuid()}
          style={{
            margin: 0,
            padding: 0
          }}
          {...rest}
        >
          {renderedItems}
          <IonText
            className={css('no-results', { 'ion-hide': !isEmpty || error })}
          >
            {zeroStateText || 'No Results'}
          </IonText>
          <IonText className={css('no-results', { 'ion-hide': !error })}>
            <p>There was a problem loading results.</p>
            {onRefresh && (
              <IonButton
                color="primary"
                fill="outline"
                onClick={() => onRefresh()}
              >
                Retry
              </IonButton>
            )}
          </IonText>
          {contentChildren}
        </IonList>
        {!isTopDirection && infiniteScroller}
        <TLoading visible={loading && !pullRefreshing} text={loadingText} />
        {fab}
      </IonContent>
      {children}
    </TPage>
  );
};

export default TInfiniteScrollPage;
