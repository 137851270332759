import React, { useContext } from 'react';
import {
  TPage,
  HapticButton,
  TItem
} from '../components';
import { IonList } from '@ionic/react';
import { AppContext } from '../context/AppContext';
import useRequireAdmin from '../hooks/useRequireAdmin';
import { copy } from 'ionicons/icons';
import {
  util
} from '../core';

const ClientWidgetPage: React.FC = () => {
  const { state } = useContext(AppContext);
  const { selectedClient } = state;
  useRequireAdmin();

  return (
    <TPage loading={false}>
      {!false && (
        <IonList lines="full">
          <TItem label="Snippet" labelPosition="stacked">
            <p style={{ fontFamily: 'Courier New' }}>{selectedClient?.credit_app_embed_code}</p>
            <HapticButton
              slot="end"
              icon={copy}
              onClick={() => util.copyToClipboard(selectedClient.credit_app_embed_code)}
            />
          </TItem>
        </IonList>
      )}
    </TPage>
  );
};

export default ClientWidgetPage;
