import React, { useState, useEffect } from 'react';
import '../../styles/components/ReportFilters.scss';
import TModal from './TModal';
import { IonList, IonButton, IonItemDivider, IonIcon } from '@ionic/react';
import TToggleItem from '../TToggleItem';
import TSelectItem from '../TSelectItem';
import TInputItem from '../TInputItem';
import ButtonGrid from '../ButtonGrid';
import { loadingIndicator, appNotification, http } from '../../core';
import { eventTypesService } from '../../services';
import { mail } from 'ionicons/icons';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState} from 'draft-js';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

export interface EventTypeModalProps {
  evType: any;
  onDidDismiss: () => any;
}

const EventTypeModal: React.FC<EventTypeModalProps> = ({
  evType,
  onDidDismiss
}) => {
  const [editorState, setEditorState] = useState(
    () => EditorState.createWithContent(stateFromHTML(evType?.followup_email_body ?? ''))
  );
  const [eventType, setEventType] = useState(evType);
  const [showFollowUpEmailFields, setShowFollowUpEmailFields] = useState(false);
  useEffect(() => setEventType(evType), [evType]);

  useEffect(() => {
    const convertContentToHTML = () => {
      const currentContent = editorState.getCurrentContent();
      const currentContentAsHTML = stateToHTML(currentContent);
      setEventType((n: any) => ({ ...n, 'followup_email_body': currentContentAsHTML }));
    }
    convertContentToHTML()
  }, [editorState]);

  const updateField = (key: string) => (e: CustomEvent) => {
    const val = e.detail.checked ?? e.detail.value;
    setEventType((n: any) => ({ ...n, [key]: val }));
  };

  const save = async () => {
    try {
      await loadingIndicator.create();
      if (evType.id) {
        await eventTypesService.update(evType.id, eventType);
      } else {
        await eventTypesService.create(eventType);
      }
      appNotification.toast('Event type saved.', 'Success');
      onDidDismiss();
    } catch (e) {
      http.onHttpError(e);
    } finally {
      loadingIndicator.dismiss();
    }
  };

  return (
    <TModal
    className="edit-client-evType-modal"
      isOpen={true}
      title={`${eventType?.id ? 'Edit' : 'Add'} Event Type`}
      onDidDismiss={onDidDismiss}
      footer={
        <ButtonGrid>
          <IonButton
            color="secondary"
            expand="block"
            disabled={!eventType?.title}
            onClick={save}
          >
            Save
          </IonButton>
        </ButtonGrid>
      }
    >
      <IonList lines="full">
        <TInputItem
          labelPosition="stacked"
          label="Title *"
          autocapitalize="on"
          onChange={updateField('title')}
          value={eventType.title}
        />
        <TInputItem
          labelPosition="stacked"
          label="Description"
          onChange={updateField('description')}
          value={eventType.description}
          multiline
        />
        <TInputItem
          labelPosition="stacked"
          label="Location"
          onChange={updateField('location')}
          value={eventType.location}
          multiline
        />
        <TSelectItem
          labelPosition="stacked"
          label="Duration Minutes"
          onChange={updateField('duration_minutes')}
          value={eventType.duration_minutes ?? 15}
          options={[5,10,15,20,30,45,60,90]}
        />
        <TToggleItem
          label="Ask for Company Name &amp; Title"
          onIonChange={updateField('is_business_event')}
          checked={!!eventType.is_business_event}
          wrapLabel
        />
        <IonItemDivider
          className="pointer"
          onClick={() => setShowFollowUpEmailFields(!showFollowUpEmailFields)}
        >
          Email Follow-Up (optional) {showFollowUpEmailFields ? '-' : '+'}
          <IonIcon icon={mail} slot="start" />
        </IonItemDivider>
        {showFollowUpEmailFields && (
          <>
            <TInputItem
              labelPosition="stacked"
              label="Timing minute(s) after"
              type="number"
              onChange={updateField('followup_email_timing_minutes')}
              value={eventType.followup_email_timing_minutes}
            />
            <TInputItem
              labelPosition="stacked"
              label="Subject"
              onChange={updateField('followup_email_subject')}
              value={eventType.followup_email_subject}
            />
            <Editor editorState={editorState} onEditorStateChange={setEditorState} />
          </>
        )}
      </IonList>
    </TModal>
  );
};

export default EventTypeModal;
