import { http } from '../core';
import qs from 'querystring';

class NinjaTeamReportService {

  async retrieveReport(
    from: Date,
    to: Date,
    ninjaTeamId?: number
  ) {
    try {
      const params = {
        day__gte: from.toISOString().split('T')[0],
        day__lt: to.toISOString().split('T')[0],
        ordering: 'day',
        ninja_team: ninjaTeamId
      } as any;
      const query = qs.stringify(params);
      const { data } = await http.authorizedRequest({
        url: `/ninja-team-reports/?${query}`,
        exclusive: false
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

}
const ninjaTeamReportService = new NinjaTeamReportService();
export default ninjaTeamReportService;
