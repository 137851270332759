import React, { useContext, useState, useEffect, useCallback } from 'react';
import {
  IonList,
  IonItem,
  IonToolbar,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonButton,
  IonButtons
} from '@ionic/react';
import { TModal } from '.';
import { AppContext } from '../../context/AppContext';
import { quickRepliesService } from '../../services';
import SendButton from '../SendButton';
import TSearchBar from '../TSearchBar';
import TClickToEditItem from '../TClickToEditItem';
import { addCircle, arrowBack } from 'ionicons/icons';
import TLoading from '../TLoading';
import { LeadContext } from '../../context/LeadContext';
import { loadingIndicator, util } from '../../core';
import '../../styles/components/QuickRepliesModal.scss';
import TSelectItem from '../TSelectItem';
import { setMessageText } from '../../context/ChatMessageBoxContext';

export interface QuickRepliesProps {
  isOpen: boolean;
  onDidDismiss?: () => any;
  setMessage?: any;
}

const QuickReplies: React.FC<QuickRepliesProps> = ({
  isOpen,
  onDidDismiss,
  setMessage=setMessageText
}) => {
  const leadContext = useContext(LeadContext);
  const { lead } = leadContext.state;
  const keys = Object.keys(lead || {});
  const [loading, setLoading] = useState(false);
  const [filteredKeys, setFilteredKeys] = useState<any>([]);
  const [category, setCategory] = useState();
  const [search, setSearch] = useState<any>();
  const [replies, setReplies] = useState<any>();
  const [next, setNext] = useState();
  const [createCategory, setCreateCategory] = useState<any>();
  const { state } = useContext(AppContext);
  const { quickReplyCategories } = state;

  const loadReplies = useCallback(async () => {
    if (category || search) {
      setLoading(true);
      const res = await quickRepliesService.list({
        search,
        category__id: category
      });

      setNext(res.next);
      setReplies(res.results);
      setLoading(false);
    } else {
      setNext(undefined);
      setReplies(undefined);
    }
  }, [category, search]);

  useEffect(() => {
    loadReplies();
  }, [loadReplies]);

  useEffect(() => setCreateCategory(category), [category]);

  const doSearch = (val: string) => setSearch(val);

  const onLoadNextPage = async () => {
    const res = await quickRepliesService.next(next);
    setNext(res.next);
    setReplies((r: any) => (r ?? []).concat(res.results));
  };

  const goBack = () => {
    setCategory(undefined);
    setReplies(undefined);
  };

  const sendReply = async (message: string) => {
    setMessage(message);
    onDidDismiss?.();
  };

  const createQuickReply = async (e: any) => {
    const text = e.detail.value.trim();

    if (text) {
      try {
        await loadingIndicator.create();
        const res = await quickRepliesService.create({
          category: createCategory,
          text: e.detail.value,
          order:
            replies?.reduce?.(
              (num: number, it: any) => Math.max(num, it.order),
              0
            ) ?? 10
        });
        setReplies((r: any) => (r ?? []).concat([res]));
      } finally {
        loadingIndicator.dismiss();
      }
    }
  };

  const editQuickReply = (reply: any) => async (e: any) => {
    const text = e.detail.value.trim();

    if (text) {
      try {
        await loadingIndicator.create();
        const res = await quickRepliesService.update(reply.id, { text });
        setReplies((r: any) =>
          util.sortByProp(
            r.filter((it: any) => it.id !== res.id).concat([res]),
            'order'
          )
        );
      } finally {
        loadingIndicator.dismiss();
      }
    }
  };

  const editPlaceholder =
    'Enter quick reply. Make sure your quick reply ends with a question.';

  const autoCompleteProps = {
    onInputValueChange: (e: any) => {
      const val = e.detail.value;

      const match = val.match(quickRepliesService.templateRegex);
      if (match) {
        const query = match[1].trim().toLowerCase();
        setFilteredKeys(keys.filter((it: string) => !!it.match(query)));
      } else {
        setFilteredKeys([]);
      }
    },
    autocompleteResults: filteredKeys,
    appendResult: (value: string, item: string) => {
      const match = value.match(quickRepliesService.templateRegex);
      const str = `{{ ${item} }} `;
      return match
        ? value.replace(quickRepliesService.templateRegex, str)
        : (value += str);
    }
  };

  return (
    <TModal
      isOpen={isOpen}
      className="quick-replies-modal"
      title="Quick Replies"
      footer={
        <TClickToEditItem
          text=""
          title="New Quick Reply"
          type="button"
          lines="none"
          buttonIcon={addCircle}
          editPlaceholder={editPlaceholder}
          onChange={createQuickReply}
          placeholderText="Add New Quick Reply"
          validate={(value: string) => !!value && !!createCategory}
          modalToolbar={
            !category && (
              <IonToolbar className="category-select">
                <TSelectItem
                  label="Category"
                  value={createCategory}
                  onChange={(e: any) =>
                    setCreateCategory(parseInt(e.detail.value))
                  }
                  options={quickReplyCategories.map((it: any) => ({
                    value: it.id,
                    text: it.name
                  }))}
                />
              </IonToolbar>
            )
          }
          {...autoCompleteProps}
        />
      }
      headerButton={
        replies && (
          <IonButtons slot="start">
            <IonButton fill="clear" onClick={goBack}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
        )
      }
      toolbar={
        <IonToolbar>
          <TSearchBar onSearch={doSearch} />
        </IonToolbar>
      }
      onDidDismiss={onDidDismiss}
    >
      <TLoading visible={loading} />
      <IonList lines="full" className="no-padding no-margin">
        {!replies &&
          quickReplyCategories.map((category: any) => (
            <IonItem
              detail
              className="pointer ion-activatable"
              key={category.id}
              onClick={() => setCategory(category.id)}
            >
              {category.name}
            </IonItem>
          ))}
        {!loading &&
          replies &&
          replies?.map?.((reply: any) => {
            const messageText = quickRepliesService.renderedMessageText(
              reply,
              lead
            );
            return (
              <TClickToEditItem
                key={reply.id}
                title="Edit Quick Reply"
                text={reply.text}
                onChange={editQuickReply(reply)}
                placeholderText={messageText}
                editPlaceholder={editPlaceholder}
                validate={(value: string) => !!value}
                disabled={!reply.user}
                {...autoCompleteProps}
              >
                <SendButton
                  fill="clear"
                  slot="end"
                  onClick={() => sendReply(messageText)}
                />
              </TClickToEditItem>
            );
          })}
        <IonInfiniteScroll
          disabled={!category || !next}
          onIonInfinite={async (e: any) => {
            await onLoadNextPage();
            e.target.complete();
          }}
        >
          <IonInfiniteScrollContent />
        </IonInfiniteScroll>
      </IonList>
    </TModal>
  );
};

export default QuickReplies;
