import React, {
  useContext,
  useCallback,
  useState,
  useEffect,
  useRef
} from 'react';
import HapticButton from './HapticButton';
import { IonIcon, IonSlides, IonSlide } from '@ionic/react';
import { heart, thumbsDown, chevronBack, chevronForward } from 'ionicons/icons';
import { PersonalizedLeadContext } from '../context/PersonalizedLeadContext';
import { util, loadingIndicator, appNotification } from '../core';
import { inventoryService } from '../services';
import { InventoryItemModal } from './modals';
import css from 'classnames';
import ButtonGrid from './ButtonGrid';

const PersonalizedLeadFeaturedVehicle: React.FC = () => {
  const [detail, setDetail] = useState<any>();
  const slides = useRef<any>();
  const { state } = useContext(PersonalizedLeadContext);
  const [response, setResponse] = useState<any>();
  const [hasPrevSlide, setHasPrevSlide] = useState(false);
  const [hasNextSlide, setHasNextSlide] = useState(true);

  const lead = state.lead;
  const leadId = lead.id;
  const clientId = lead?.client?.id;
  const vehicle = state.featureData;
  const vehicleId = vehicle?.id;
  useEffect(
    () =>
      setResponse(
        lead?.inventory_likes?.some((it: any) => it.inventory.id === vehicleId)
      ),
    [lead, vehicleId]
  );

  const respond = useCallback(
    (like: boolean) => async () => {
      try {
        await loadingIndicator.create();
        await inventoryService.showcaseResponse(
          clientId,
          leadId,
          vehicleId,
          like
        );
        appNotification.alertSimple(
          like
            ? `Thank you for letting us know, ${lead?.first_name}! We're glad you love it! We'll be in touch soon.`
            : `Not to worry, ${lead?.first_name}, we'll find you a vehicle you love. We'll be in touch soon.`,
          'Thanks!'
        );
        setResponse(like);
      } finally {
        await loadingIndicator.dismiss();
      }
    },
    [clientId, leadId, vehicleId, lead]
  );

  const onSlideNavigate = async (current: any) => {
    setHasPrevSlide(!(await current.isBeginning()));
    setHasNextSlide(!(await current.isEnd()));
  };

  const prevSlide = async () => {
    if (slides.current) {
      await slides.current?.slidePrev();
      await onSlideNavigate(slides.current);
    }
  };

  const nextSlide = async () => {
    if (slides.current) {
      await slides.current.slideNext();
      await onSlideNavigate(slides.current);
    }
  };

  if (state.feature !== 'vehicle') {
    return null;
  }

  return (
    <>
      <div className="featured-vehicle">
        <h4>What do you think of this {vehicle?.make ?? 'vehicle'}?</h4>
        <div className="slides-container">
          <IonIcon
            className={css('arrow', { disabled: !hasPrevSlide })}
            icon={chevronBack}
            onClick={prevSlide}
          />
          <IonSlides ref={slides}>
            {vehicle?.images_list?.map((it: any) => (
              <IonSlide key={it.url}>
                <img
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src="/assets/car-placeholder.jpg";
                  }}
                  src={it.url}
                  alt={util.ymmt(vehicle)}
                />
              </IonSlide>
            ))}
          </IonSlides>
          <IonIcon
            className={css('arrow', { disabled: !hasNextSlide })}
            onClick={nextSlide}
            icon={chevronForward}
          />
        </div>
        <div className="vehicle-info">
          <div className="vehicle-info-text">
            <strong>
              {vehicle?.condition} {vehicle?.year} {vehicle?.make}
            </strong>
            <span className="vehicle-info-mt">
              {vehicle?.model} {vehicle?.trim}
            </span>
          </div>
          <div className="vehicle-price">
            {inventoryService.getSellingPrice(vehicle?.selling_price)}
          </div>
        </div>
        {detail && (
          <InventoryItemModal
            isOpen={true}
            onDidDismiss={() => setDetail(null)}
            liked={response}
            item={detail}
            leadHash={state.token}
            clientId={lead?.client?.id}
            leadId={lead?.id}
          />
        )}
      </div>
      <ButtonGrid className="featured-vehicle-buttons">
        <HapticButton
          fill="solid"
          color="danger"
          icon={heart}
          size="small"
          expand="block"
          onClick={respond(true)}
        >
          Love
        </HapticButton>
        <HapticButton
          fill="solid"
          color="primary"
          size="small"
          expand="block"
          icon={thumbsDown}
          onClick={respond(false)}
        >
          Don't Love
        </HapticButton>
        <HapticButton
          color="secondary"
          size="small"
          onClick={() => setDetail({ inventory: vehicle })}
        >
          Details
        </HapticButton>
      </ButtonGrid>
    </>
  );
};

export default PersonalizedLeadFeaturedVehicle;
