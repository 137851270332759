import React, { useState, useRef, useCallback, useContext } from 'react';
import {
  IonPage,
  IonContent,
  IonHeader,
  IonFooter,
  IonButtons,
  IonToolbar,
  IonBackButton,
  useIonViewDidEnter
} from '@ionic/react';
import AddLeadModal from './modals/AddLeadModal';
import { AppContext } from '../context/AppContext';
import TLoading from './TLoading';
import TMenuButton from './TMenuButton';
import AddLeadButton from './AddLeadButton';
import Dropzone from './Dropzone';
import { useLocation } from 'react-router-dom';
import { analytics, util, pages } from '../core';
import THeaderLogo from './THeaderLogo';
import { pushNotifications } from '../services';

export interface TPageProps {
  loading?: boolean;
  loadingText?: string;
  logoType?: string;
  error?: boolean;
  onRetry?: () => void;
  noFooter?: boolean;
  noHeader?: boolean;
  noClientSelector?: boolean;
  noContent?: boolean;
  children?: any;
  customFooterContent?: JSX.Element;
  customHeaderContent?: JSX.Element;
  onDrop?: (e: any) => void;
  isDropSupported?: (e: any) => boolean;
  headerTool?: JSX.Element;
  scrollX?: boolean;
  scrollY?: boolean;
  preContent?: any;
  headerId?: string;
  footerId?: string;
  header?: string;
  id?: string;
}

const TPage: React.FC<TPageProps> = ({
  loading,
  noContent,
  scrollX = false,
  scrollY = true,
  children,
  noHeader,
  noFooter,
  customHeaderContent,
  customFooterContent,
  onDrop,
  isDropSupported,
  headerTool,
  preContent,
  loadingText,
  logoType,
  headerId,
  footerId,
  id
}) => {
  const { dispatch, state } = useContext(AppContext);

  const [showDropZone, setShowDropZone] = useState(false);
  const dragCount = useRef<number>(0);
  const location = useLocation();

  const headerContent = customHeaderContent || <THeaderLogo logoType={logoType} />;

  const footerContent = customFooterContent;

  useIonViewDidEnter(() => {
    const selectedPage = util.getSelectedPage(location, pages);
    analytics.setCurrentScreen(selectedPage?.title ?? 'Unknown');
    pushNotifications.removeAllDeliveredNotifications();
    analytics.track(
      `Viewed${selectedPage?.title?.replace?.(/\s/g, '') ?? 'Page'}`,
      location
    );
  });

  const onDragEnter = useCallback(() => {
    dragCount.current++;
  }, []);

  const onDragOver = useCallback(
    (e: any) => {
      e.preventDefault();
      if (onDrop) {
        if (isDropSupported && !isDropSupported(e)) {
          e.dataTransfer.dropEffect = 'none';
        } else {
          e.dataTransfer.dropEffect = 'copy';
        }
        setShowDropZone(true);
      }
    },
    [isDropSupported, onDrop]
  );

  const onDragLeave = useCallback((e: any) => {
    dragCount.current--;
    if (dragCount.current === 0) {
      setShowDropZone(false);
    }
  }, []);

  return (
    <IonPage
      onDrop={(e: any) => {
        setShowDropZone(false);
        dragCount.current = 0;
        if (onDrop) {
          onDrop(e);
        }
      }}
      className="t-page"
      id={id}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
    >
      {showDropZone && <Dropzone />}
      {!noHeader && (
        <IonHeader id={headerId}>
          <IonToolbar mode="ios">
            <IonButtons slot="start">
              <IonBackButton />
              <TMenuButton />
            </IonButtons>
            {headerContent}
            <IonButtons slot="end">
              {headerTool ?? <AddLeadButton/>}
            </IonButtons>
          </IonToolbar>
        </IonHeader>

      )}
      {preContent}
      {!noContent ? (
        <IonContent scrollX={scrollX} scrollY={scrollY}>
          {children}
          <TLoading visible={loading} text={loadingText} />
        </IonContent>
      ) : (
        children
      )}
      {!noFooter && footerContent && (
        <IonFooter
          id={footerId}
          className={!customFooterContent ? 'ion-hide-md-up' : ''}
        >
          <IonToolbar>{footerContent}</IonToolbar>
        </IonFooter>
      )}
      {state.showAddLeadModal && (
        <AddLeadModal
          isOpen={true}
          onDidDismiss={() => {
            dispatch({ type: 'set', value: { showAddLeadModal: false } });
          }}
        />
      )}
    </IonPage>
  );
};

export default TPage;
