import React from 'react';
import { TItemProps, default as TItem } from './TItem';
import { IonSelect, IonSelectOption } from '@ionic/react';

export interface TSelectItemProps extends TItemProps {
  value?: any;
  defaultValue?: string;
  options: any[];
  interface?: any;
  optionValueKey?: string;
  optionTextKey?: string;
  onChange?: (e: CustomEvent) => any;
  placeholder?: string;
  multiple?: boolean;
  selectedText?: string;
}

const TSelectItem: React.FC<TSelectItemProps> = ({
  options,
  interface: interfc,
  optionValueKey = 'value',
  optionTextKey = 'text',
  value,
  onChange,
  children,
  labelPosition,
  defaultValue,
  placeholder,
  multiple,
  selectedText,
  ...rest
}) => {
  return (
    <TItem labelPosition={labelPosition} {...rest}>
      {children}
      <IonSelect
        style={{
          textAlign: labelPosition === 'stacked' ? null : 'right'
        }}
        interface={interfc}
        value={value}
        defaultValue={defaultValue}
        onIonChange={onChange}
        placeholder={placeholder}
        multiple={multiple}
        selectedText={selectedText}
      >
        {options.map(it => {
          const value = typeof it === 'object' ? it[optionValueKey] : it;
          const text =
            typeof it === 'object' ? it[optionTextKey || 'text'] : it;
          return (
            <IonSelectOption key={value ? typeof value === 'object' ? JSON.stringify(value) : value : text} value={value}>
              {text}
            </IonSelectOption>
          );
        })}
      </IonSelect>
    </TItem>
  );
};

export default TSelectItem;
