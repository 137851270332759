import React, { useContext } from 'react';
import { IonItem, IonLabel, IonText, IonIcon } from '@ionic/react';
import moment from 'moment';
import ActionBar, { ActionBarItem } from './ActionBar';
import { chatbubbles, volumeOff, share } from 'ionicons/icons';
import InboundCall from '../resources/inbound.png';
import OutboundCall from '../resources/outbound.png';
import { sharing } from '../core';
import TAudio from './TAudio';
import PhoneCallChips from './PhoneCallChips';
import AudioNotAvailable from './AudioNotAvailable';
import '../styles/components/PhoneCallRow.scss';
import { AppContext } from '../context/AppContext';
import css from 'classnames';

export interface PhoneCallRowProps {
  item: any;
  expanded: boolean;
  fullLines?: boolean;
  onClick: (e: any) => void;
}

const PhoneCallRow: React.FC<PhoneCallRowProps> = (
  props: PhoneCallRowProps
) => {
  const { state } = useContext(AppContext);
  const { item, expanded, onClick, fullLines } = props;
  const {
    created_at,
    direction,
    summary,
    audio_file,
    duration,
    user,
    lead,
    category,
  } = item;

  const { assigned_to, lead_source, status } = lead ?? {};

  const inbound = direction === 'inbound';

  const toolbarItems = [
    {
      label: 'Text',
      icon: chatbubbles,
      slot: 'end',
      routerLink: lead?.id
        ? `/text-messages/conversation/${state.selectedClientId}/${lead?.id}/`
        : undefined,
    },
    {
      label: 'Share',
      icon: share,
      slot: 'end',
      disabled: !audio_file,
      action: () => sharing.share(audio_file, `Phone Call`),
    },
  ] as ActionBarItem[];

  const assignment = inbound ? assigned_to || 'Unassigned' : user.full_name;

  return (
    <>
      <IonItem
        className={css('call-row ion-activatable', {
          expanded,
          'with-audio': !!audio_file,
        })}
        lines={fullLines ? 'full' : 'inset'}
        onClick={onClick}
      >
        <img
          src={inbound ? InboundCall : OutboundCall}
          className='call-icon'
          alt={inbound ? 'Inbound Call' : 'Outbound Call'}
          slot='start'
        />
        <IonLabel>
          <IonText className='ion-multiline'>
            <h3>{lead?.full_name}</h3>
            <div className='detail lead-details'>
              <span className='ion-hide-sm-down'>
                {status ?? 'No Status'} •{' '}
              </span>
              <span>{assignment} • </span>
              <span>{lead_source ?? 'No Lead'}</span>
            </div>
            <div
              style={{
                marginTop: 10,
                marginBottom: 10,
                fontSize: '.8rem',
                whiteSpace: 'normal',
              }}
            >
              {summary?.replace(/\[(\d+)\]/g, 'Score: $1')}
            </div>
            {category && <PhoneCallChips call={item} />}
          </IonText>
        </IonLabel>
        <IonText className='call-row-detail' slot='end'>
          <span>{moment(created_at).fromNow()}</span>
          <span className='light duration'>
            {moment.utc(duration * 1000).format('mm:ss')}
            {!audio_file && (
              <IonIcon className='no-audio-icon' icon={volumeOff} />
            )}
          </span>
        </IonText>
      </IonItem>
      {expanded && (
        <IonItem className='call-row-tools' lines='full'>
          <IonLabel class='ion-text-wrap call-row-actions'>
            {audio_file ? (
              <TAudio autoplay src={audio_file} duration={duration} />
            ) : (
              <AudioNotAvailable />
            )}
            <ActionBar items={toolbarItems} />
          </IonLabel>
        </IonItem>
      )}
    </>
  );
};

export default PhoneCallRow;
