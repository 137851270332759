import React, { useState, useContext } from 'react';
import TModal from './TModal';
import { AppContext } from '../../context/AppContext';
import TSelectItem from '../TSelectItem';
import TItem from '../TItem';
import { IonList, IonButton, IonChip, IonLabel, IonIcon } from '@ionic/react';
import { modalController } from '@ionic/core';
import { TInputItem, ButtonGrid, TToggleItem } from '..';
import {
  chatbubble,
  chatboxEllipses,
  timer,
  key,
  closeCircle,
  checkmarkCircleOutline,
  call as callIcon
} from 'ionicons/icons';
import {
} from '../../core';
export interface AutoReplyModalProps {
  onDidDismiss?: () => any;
  callBack: (data: any) => any;
  intent: string;
  pauseDurationChoices: any;
}

const AutoReplyModal: React.FC<AutoReplyModalProps> = ({
  onDidDismiss,
  callBack,
  intent,
  pauseDurationChoices,
}) => {
  const appContext = useContext(AppContext);

  const handleEnterPressed = (e: any) => {
    addKeyword(e.target.value);
    e.target.value = '';
  }

  const capitalize = (s: string) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  const [autoReply, setAutoReply] = useState({
    intent,
    message: intent === 'positive' ? 'When can you come in?' : '',
    pause_duration_hours: '',
    pause_message: '',
    keywords: intent === 'positive' ? ['Yes','Yeah','OK','Okay'] : ['No','Not yet','Leave me alone'],
    status: '',
    needs_call: false
  });
  const updateAutoReply = (key: string) => async (e: CustomEvent) => {
    const obj: any = Object.assign({}, autoReply);
    const val = e.detail.checked ?? e.detail.value;
    obj[key] = val;
    setAutoReply(obj);
  };

  const addKeyword = (val: string) => {
    const obj: any = Object.assign({}, autoReply);
    obj.keywords.push(val);
    setAutoReply(obj);
  }

  const removeKeyword = (index: number) => {
    const obj: any = Object.assign({}, autoReply);
    obj.keywords.splice(index, 1);
    setAutoReply(obj);
  }

  return (
    <TModal
      isOpen={true}
      onDidDismiss={onDidDismiss}
      title={`${capitalize(intent)} Blast Auto Reply`}
      footer={
        <ButtonGrid>
          <IonButton
            color="secondary"
            expand="block"
            onClick={() => {
              callBack(autoReply);
              modalController.dismiss();
            }}
          >
            Save
          </IonButton>
        </ButtonGrid>
      }
    >
        <IonList>
          <TInputItem
            icon={key}
            label="Key Phrases"
            labelPosition="stacked"
            onEnterPressed={handleEnterPressed}
            lines="none"
          />
          <TItem lines="full">
          {autoReply.keywords.map((keyword, index) => (
            <IonChip key={index}>
              <IonLabel>{keyword}</IonLabel>
              <IonIcon
                color="primary"
                icon={closeCircle}
                onClick={(e: any) => {
                  e.stopPropagation();
                  removeKeyword(index);
                }}
              />
            </IonChip>
          ))}
          </TItem>
          <TInputItem
            icon={chatbubble}
            label="Message"
            multiline={true}
            value={autoReply.message}
            onChange={updateAutoReply("message")}
          />
          <TSelectItem
            label="Set Lead Status"
            value={autoReply.status}
            onChange={updateAutoReply('status')}
            options={[
              { value: '', text: 'Select' },
              ...appContext.state.statuses?.map?.(it => ({
                value: it.id,
                text: it.option_name
              }))
            ]}
            icon={checkmarkCircleOutline}
          />
          <TSelectItem
            label="Hours to Pause Lead"
            value={autoReply.pause_duration_hours}
            onChange={updateAutoReply('pause_duration_hours')}
            options={pauseDurationChoices}
            icon={timer}
          />
          {!!autoReply.pause_duration_hours && (
            <TInputItem
              icon={chatboxEllipses}
              label="Pause Message (optional)"
              multiline={true}
              value={autoReply.pause_message}
              onChange={updateAutoReply('pause_message')}
            />
          )}
        </IonList>
        <TToggleItem
          lines="full"
          icon={callIcon}
          label="Needs Call"
          checked={autoReply.needs_call}
          onIonChange={updateAutoReply('needs_call')}
        />
    </TModal>
  );
};

export default AutoReplyModal;
