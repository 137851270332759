import React, { useState, useCallback, useEffect } from 'react';
import { IonRange, IonGrid, IonRow, IonCol } from '@ionic/react';

import { INVENTORY_DEFAULT_PRICE_RANGE, INVENTORY_DEFAULT_MILEAGE_RANGE } from '../services/inventory';
import TSelectItem from './TSelectItem';
import TInputItem from './TInputItem';
import TItem from './TItem';
import { useDebounce } from '../hooks'
import { inventoryService } from '../services';
import '../styles/components/InventoryFilters.scss';

export interface InventoryFiltersProps {
  priceMin?: number;
  priceMax?: number;
  mileageMin?: number;
  mileageMax?: number;
  clientMin?: number;
  clientId?: number;
  clientMax?: number;
  bodyStyle?: any[];
  ordering?: string;
  yearName?: any[];
  makeName?: any[];
  exteriorColorName?: any[];
  driveTypeName?: any[];
  transmissionTypeName?: any[];
  engineName?: any[];
  fuelTypeName?: any[];
  modelName?: any[];
  trimName?: any[];
  condition?: string;
  search?: string;
  onConditionChange?: (condition: string) => any;
  onPriceMinChange?: (min: number) => any;
  onPriceMaxChange?: (max: number) => any;
  onMileageMinChange?: (min: number) => any;
  onMileageMaxChange?: (max: number) => any;
  onBodyStyleChange?: (styles: any[]) => any;
  onYearNameChange?: (yearNames: any[]) => any;
  onMakeNameChange?: (makeNames: any[]) => any;
  onExteriorColorNameChange?: (exteriorColorNames: any[]) => any;
  onOrderingChange?: (exteriorColorNames: any[]) => any;
  onDriveTypeNameChange?: (driveTypeNames: any[]) => any;
  onTransmissionTypeNameChange?: (transmissionTypeNames: any[]) => any;
  onEngineNameChange?: (engineNames: any[]) => any;
  onFuelTypeNameChange?: (fuelTypeNames: any[]) => any;
  onModelNameChange?: (modelNames: any[]) => any;
  onTrimNameChange?: (trimNames: any[]) => any;
}

const InventoryFilters: React.FC<InventoryFiltersProps> = ({
  bodyStyle,
  ordering,
  yearName,
  makeName,
  exteriorColorName,
  driveTypeName,
  transmissionTypeName,
  engineName,
  fuelTypeName,
  modelName,
  trimName,
  condition,
  search,
  onConditionChange,
  onPriceMinChange,
  onPriceMaxChange,
  onMileageMinChange,
  onMileageMaxChange,
  onBodyStyleChange,
  onYearNameChange,
  onMakeNameChange,
  onExteriorColorNameChange,
  onOrderingChange,
  onDriveTypeNameChange,
  onTransmissionTypeNameChange,
  onEngineNameChange,
  onFuelTypeNameChange,
  onModelNameChange,
  onTrimNameChange,
  clientId,
  mileageMin = INVENTORY_DEFAULT_MILEAGE_RANGE.Min,
  mileageMax = INVENTORY_DEFAULT_MILEAGE_RANGE.Max,
  priceMin = INVENTORY_DEFAULT_PRICE_RANGE.Min,
  priceMax = INVENTORY_DEFAULT_PRICE_RANGE.Max,
  clientMin = INVENTORY_DEFAULT_PRICE_RANGE.Min,
  clientMax = INVENTORY_DEFAULT_PRICE_RANGE.Max
}) => {

  const [filterOptions, setFilterOptions] = useState<any>();

  // Price is not handled with a text input, not a range slider so we use debounce.
  const priceMinChanged = useDebounce((value: any) => onPriceMinChange?.(value), 150);
  const priceMaxChanged = useDebounce((value: any) => onPriceMaxChange?.(value), 150);

  const fetchFilterOptions = useCallback(async () => {
    setFilterOptions(await inventoryService.fetchFilterOptions({
      clientId,
      search,
      bodyStyle,
      yearName,
      makeName,
      exteriorColorName,
      driveTypeName,
      transmissionTypeName,
      engineName,
      fuelTypeName,
      modelName,
      trimName,
      priceMin,
      priceMax,
      mileageMin,
      mileageMax,
      condition
    }));
  }, [clientId,
      search,
      bodyStyle,
      yearName,
      makeName,
      exteriorColorName,
      driveTypeName,
      transmissionTypeName,
      engineName,
      fuelTypeName,
      modelName,
      trimName,
      priceMin,
      priceMax,
      mileageMin,
      mileageMax,
      condition
    ]);

  useEffect(() => {
    fetchFilterOptions();
  }, [
        fetchFilterOptions,
        clientId,
        search,
        bodyStyle,
        ordering,
        yearName,
        makeName,
        exteriorColorName,
        driveTypeName,
        transmissionTypeName,
        engineName,
        fuelTypeName,
        modelName,
        trimName,
        priceMin,
        priceMax,
        mileageMin,
        mileageMax,
        condition
      ]);

  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <TSelectItem
            lines="none"
            label="Condition"
            labelPosition="stacked"
            value={condition}
            onChange={(e: any) => onConditionChange?.(e.detail.value)}
            options={[
              { value: '', text: 'All' },
              { value: 'new', text: 'New' },
              { value: 'used', text: 'Used' }
            ]}
          />
        </IonCol>
        <IonCol>
          {!!filterOptions?.year_name && (
            <TSelectItem
              lines="none"
              label="Year"
              labelPosition="stacked"
              className="body-style-item"
              multiple
              placeholder="All"
              value={yearName}
              optionTextKey="label"
              onChange={(e: any) => onYearNameChange?.(e.detail.value)}
              options={filterOptions.year_name}
            />
          )}
        </IonCol>
        <IonCol>
          {!!filterOptions?.make_name && (
            <TSelectItem
              lines="none"
              label="Make"
              labelPosition="stacked"
              className="body-style-item"
              multiple
              placeholder="All"
              value={makeName}
              optionTextKey="label"
              onChange={(e: any) => onMakeNameChange?.(e.detail.value)}
              options={filterOptions.make_name}
            />
          )}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          {!!filterOptions?.model_name && (
            <TSelectItem
              lines="none"
              label="Model"
              labelPosition="stacked"
              className="body-style-item"
              multiple
              placeholder="All"
              value={modelName}
              optionTextKey="label"
              onChange={(e: any) => onModelNameChange?.(e.detail.value)}
              options={filterOptions.model_name}
            />
          )}
        </IonCol>
        <IonCol>
          {!!filterOptions?.trim_name && (
            <TSelectItem
              lines="none"
              label="Trim"
              labelPosition="stacked"
              className="body-style-item"
              multiple
              placeholder="All"
              value={trimName}
              optionTextKey="label"
              onChange={(e: any) => onTrimNameChange?.(e.detail.value)}
              options={filterOptions.trim_name}
            />
          )}
        </IonCol>
        <IonCol>
          {!!filterOptions?.drive_type_name && (
            <TSelectItem
              lines="none"
              label="Drive"
              labelPosition="stacked"
              className="body-style-item"
              multiple
              placeholder="All"
              value={driveTypeName}
              optionTextKey="label"
              onChange={(e: any) => onDriveTypeNameChange?.(e.detail.value)}
              options={filterOptions.drive_type_name}
            />
          )}
        </IonCol>
      </IonRow>

      <IonRow>

        <IonCol>
          {filterOptions?.transmission_type_name && (
            <TSelectItem
              lines="none"
              label="Transmission"
              labelPosition="stacked"
              className="body-style-item"
              multiple
              placeholder="All"
              value={transmissionTypeName}
              optionTextKey="label"
              onChange={(e: any) => onTransmissionTypeNameChange?.(e.detail.value)}
              options={filterOptions.transmission_type_name}
            />
          )}
        </IonCol>
        <IonCol>
          {!!filterOptions?.engine_name && (
            <TSelectItem
              lines="none"
              label="Engine"
              labelPosition="stacked"
              className="body-style-item"
              multiple
              placeholder="All"
              value={engineName}
              optionTextKey="label"
              onChange={(e: any) => onEngineNameChange?.(e.detail.value)}
              options={filterOptions.engine_name}
            />
          )}
        </IonCol>
        <IonCol>
          {!!filterOptions?.fuel_type_name && (
            <TSelectItem
              lines="none"
              label="Fuel"
              labelPosition="stacked"
              className="body-style-item"
              multiple
              placeholder="All"
              value={fuelTypeName}
              optionTextKey="label"
              onChange={(e: any) => onFuelTypeNameChange?.(e.detail.value)}
              options={filterOptions.fuel_type_name}
            />
          )}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          {!!filterOptions?.body_name && (
            <TSelectItem
              lines="none"
              label="Body"
              labelPosition="stacked"
              className="body-style-item"
              multiple
              placeholder="All"
              value={bodyStyle}
              optionTextKey="label"
              onChange={(e: any) => onBodyStyleChange?.(e.detail.value)}
              options={filterOptions.body_name}
            />
          )}
        </IonCol>
        <IonCol>
          {!!filterOptions?.exterior_color_name && (
            <TSelectItem
              lines="none"
              label="Color"
              labelPosition="stacked"
              className="body-style-item"
              multiple
              placeholder="All"
              value={exteriorColorName}
              optionTextKey="label"
              onChange={(e: any) => onExteriorColorNameChange?.(e.detail.value)}
              options={filterOptions.exterior_color_name}
            />
          )}
        </IonCol>
        <IonCol>
            <TSelectItem
              lines="none"
              label="Ordering"
              labelPosition="stacked"
              placeholder="Default"
              value={ordering}
              onChange={(e: any) => onOrderingChange?.(e.detail.value)}
              options={[
                {value: '', text: 'Default'},
                {value: 'selling_price', text: 'Low to High'},
                {value: '-selling_price', text: 'High to Low'}
              ]}
            />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <TInputItem
            lines="none"
            type="number"
            inputMode="tel"
            min={String(clientMin)}
            max={String(clientMax)}
            label="Min Price"
            labelPosition="stacked"
            value={String(priceMin)}
            onChange={(e: any) => priceMinChanged?.(e.detail.value)}
          />
        </IonCol>
        <IonCol>
          <TInputItem
            lines="none"
            type="number"
            inputMode="tel"
            min={String(clientMin)}
            max={String(clientMax)}
            label="Max Price"
            labelPosition="stacked"
            value={String(priceMax)}
            onChange={(e: any) => priceMaxChanged?.(e.detail.value)}
          />
        </IonCol>
        <IonCol>
          <TItem
            className="price-range-item"
            label="Mileage"
            labelPosition="stacked"
            lines="none"
          >
            <div className="ion-multiline price-range-container">
              <IonRange
                dualKnobs
                value={{ lower: mileageMin, upper: mileageMax }}
                min={INVENTORY_DEFAULT_MILEAGE_RANGE.Min}
                max={INVENTORY_DEFAULT_MILEAGE_RANGE.Max}
                step={1000}
                pin
                debounce={500}
                slot="end"
                onIonChange={(e: any) => {
                  const { lower, upper } = e.detail.value;
                  onMileageMinChange?.(lower);
                  onMileageMaxChange?.(upper);
                }}
              />
              <div className="price-range">
                <span className="price-range-label">
                  {mileageMin}
                </span>
                <span className="price-range-label">
                  {mileageMax}
                </span>
              </div>
            </div>
          </TItem>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default InventoryFilters;
