import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import {
  IonIcon,
  IonButton,
  IonRange,
  IonText,
  IonSpinner
} from '@ionic/react';
import {
  speedometer,
  play,
  pause,
  shareOutline,
  stopwatch
} from 'ionicons/icons';
import { haptics, sharing } from '../core';
import css from 'classnames';
import '../styles/components/TAudio.scss';

export interface TAudioProps {
  src: string;
  duration?: number;
  autoplay?: boolean;
  onCanPlay?: () => void;
  onEnded?: () => void;
  noTime?: boolean;
  noSpeed?: boolean;
  background?: boolean;
  allowShare?: boolean;
  shareSrc?: string;
  showLength?: boolean;
}

const TAudio: React.FC<TAudioProps> = (props: TAudioProps) => {
  const player = useRef<any>();
  const playbackRates = [0.75, 1, 1.5, 2];
  const [playing, setPlaying] = useState(props.autoplay === true);
  const [loading, setLoading] = useState(true);
  const [playbackRate, setPlaybackRate] = useState(1.0);
  const {
    src,
    shareSrc,
    onEnded,
    noSpeed,
    noTime,
    background,
    allowShare,
    showLength
  } = props;
  const [seeking, setSeeking] = useState(false);
  const [playbackSecond, setPlaybackSecond] = useState(0);
  const [duration, setDuration] = useState(props.duration || 0);

  const togglePlaybackRate = () => {
    const nextRate =
      playbackRates[playbackRates.indexOf(playbackRate) + 1] ||
      playbackRates[0];
    setPlaybackRate(nextRate);
    haptics.lightImpact();
  };

  useEffect(() => {
    if (player.current) {
      player.current.playbackRate = playbackRate;
    }
  }, [player, playbackRate]);

  const playAudio = () => {
    haptics.lightImpact();
    if (playing) {
      player.current?.pause?.();
    } else {
      player.current?.play?.();
    }
    setPlaying(!playing);
  };

  const setIsSeeking = () => setSeeking(true);
  const setIsNotSeeking = () => setSeeking(false);
  const onRangeChange = (e: any) => {
    if (player.current && seeking) {
      const time = e.detail.value;
      player.current.currentTime = time * duration;
    }
  };
  const shareAudio = () => sharing.share(shareSrc ?? src, 'Phone Call');
  const onCanPlay = (e: any) => {
    if (props.onCanPlay) {
      props.onCanPlay();
    }

    setLoading(false);

    setDuration(e.target.duration);
  };
  const onTimeUpdate = (e: any) =>
    setPlaybackSecond(e.target.currentTime / duration);

  const onPlayEnded = () => {
    setPlaying(false);
    setPlaybackSecond(0);
    if (onEnded) {
      onEnded();
    }
  };

  const durationString = moment.utc(duration * 1000).format('mm:ss');

  return (
    <>
      {showLength && (
        <div className="call-duration">
          <IonIcon icon={stopwatch} />
          <label>{durationString}</label>
        </div>
      )}
      <IonRange
        className={css('audio-range no-padding no-margin', {
          'audio-background': background
        })}
        min={0}
        max={1}
        step={0.01}
        value={playbackSecond}
        onMouseDown={setIsSeeking}
        onMouseUp={setIsNotSeeking}
        onTouchStart={setIsSeeking}
        onTouchEnd={setIsNotSeeking}
        onIonChange={onRangeChange}
      >
        <IonButton slot="start" fill="clear" size="small" onClick={playAudio}>
          {(!playing || !loading) && <IonIcon icon={playing ? pause : play} />}
          {playing && loading && <IonSpinner name="dots" />}
        </IonButton>

        {!noTime && (
          <>
            <IonText slot="start" className="subtitle">
              {moment.utc(duration * playbackSecond * 1000).format('mm:ss')}
            </IonText>
            <IonText slot="end" className="subtitle">
              {durationString}
            </IonText>
          </>
        )}
        {!noSpeed && (
          <IonButton
            onClick={togglePlaybackRate}
            slot="end"
            fill="clear"
            className="btn-sm-padding"
            style={{
              textTransform: 'lowercase',
              width: 60
            }}
          >
            <IonText style={{ marginRight: 5 }}>{playbackRate}x</IonText>
            <IonIcon icon={speedometer} />
          </IonButton>
        )}
        {allowShare && (
          <IonButton
            slot="end"
            fill="clear"
            className="btn-sm-padding"
            onClick={shareAudio}
          >
            <IonIcon icon={shareOutline} />
          </IonButton>
        )}
      </IonRange>
      <audio
        autoPlay={props.autoplay}
        ref={player}
        onCanPlay={onCanPlay}
        onTimeUpdate={onTimeUpdate}
        onEnded={onPlayEnded}
        preload="metadata"
        src={src}
      />
    </>
  );
};

export default TAudio;
