import React, { useRef, useMemo, forwardRef, useImperativeHandle } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/components/TDatePicker.scss';
import { IonLabel, IonIcon } from '@ionic/react';
import { calendar } from 'ionicons/icons';
import { useWindowSize } from '../hooks';
import css from 'classnames';
import { environment, native } from '../core';
import { utcToZonedTime, format } from 'date-fns-tz';

export interface TDatePickerProps extends Partial<ReactDatePickerProps> {
  label?: string;
  withPortal?: boolean,
  timezone: string;
  onDateChange: (date: string) => any;
  dateString: string | null | undefined;
}

const CustomDateInput = forwardRef(
  ({ value, className, ...props }: any, ref) => {
    const inputRef = useRef<any>();
    useImperativeHandle(ref, () => inputRef.current);
    return (
      <button
        ref={inputRef}
        className={css(className, 'custom-date-input')}
        {...props}
      >
        {value}
      </button>
    );
  }
);
const TDatePicker: React.FC<TDatePickerProps> = ({
  label,
  withPortal,
  inline,
  timezone,
  selected,
  dateString,
  onDateChange,
  onChange,
  ...rest
}) => {
  const picker = useRef<any>();
  const { width } = useWindowSize();
  const smallViewport = useMemo(() => {
    if (withPortal === false) {
      return false;
    }
    return (width ?? window.innerWidth) <= 640;
  }, [width, withPortal]);

  if ((selected || onChange) && environment.isDevelopment) {
    console.warn(
      '[TDatePicker] Due to timezone issues, selected and onChange are ignored. Please use dateString and onDateChange.'
    );
  }

  const value = dateString
    ? utcToZonedTime(new Date(dateString), timezone)
    : null;
  const onChanged = (d: Date) =>
    onDateChange(
      format(d, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", { timeZone: timezone })
    );
    const popperModifiers: any = [
      { name: 'preventOverflow', enabled: true }
    ];

  return (
    <div className={css('t-datepicker-container', { inline })}>
      <div className="t-datepicker-label-control">
        {label && <IonLabel>{label}</IonLabel>}
        <DatePicker
          ref={picker}
          inline={inline}
          selected={value}
          onChange={onChanged}
          className="t-datepicker"
          customInput={native.isNative && <CustomDateInput />}
          calendarClassName="t-datepicker-calendar"
          dateFormat="MM/dd/yyyy"
          timeFormat="h:mma"
          placeholderText="Choose Date"
          popperPlacement="bottom-start"
          fixedHeight
          withPortal={smallViewport}
          popperModifiers={popperModifiers}
          {...rest}
        />
      </div>
      {!inline && <div className="spacer" />}
      {!inline && (
        <IonIcon
          className="open-calendar"
          icon={calendar}
          onClick={() => picker.current?.setOpen?.(true)}
        />
      )}
    </div>
  );
};

export default TDatePicker;
