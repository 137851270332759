import { http } from '../core';

class AiRepliesService {
  async list(clientId: number|string, leadId: number|string) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: `/clients/${clientId}/ai-reply-suggestions/${leadId}/`
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

const aiRepliesService = new AiRepliesService();
export default aiRepliesService;
