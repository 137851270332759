import React, { FC, useState } from 'react';
import { IonItem, IonLabel, IonText, IonInput, IonIcon } from '@ionic/react';
import { Controller, Control } from 'react-hook-form';
import { TInputProps, default as TInput } from './TInput';
// @ts-ignore
import Tags from '@yaireo/tagify/dist/react.tagify';
import '@yaireo/tagify/dist/tagify.css';

type CombinedProps = React.ComponentProps<typeof IonInput>;

export interface ControlledInputProps extends CombinedProps {
  name: string;
  control?: Control;
  label?: string;
  errors?: any;
  multiline?: boolean;
  tagify?: boolean;
  pattern?: any;
  maxTags?: number;
  icon?: any;
  mask?: TInputProps['mask'];
  maskPlaceholder?: TInputProps['maskPlaceholder'];
}

const ControlledInput: FC<ControlledInputProps> = props => {
  const [itemFocusCssClass, setItemFocusCssClass] = useState<string>('');
  const [itemValueCssClass, setItemValueCssClass] = useState<string>('');
  const {
  name,
  control,
  label,
  errors,
  multiline,
  tagify,
  pattern,
  maxTags,
  icon,
  ...rest
} = props;
  return (
    <>
      <IonItem className={`${itemFocusCssClass} ${itemValueCssClass}`}>
        {label && <IonLabel position="floating">{label}</IonLabel>}
        <Controller
          render={({ field: { onChange, onBlur, value } }) => tagify ? (
            <div className="t-tags-wrap">
              <Tags
                placeholder={label}
                // pattern={pattern}
                value={value?.split('\n')}
                settings={{pattern, maxTags}}
                onFocus={()=>setItemFocusCssClass('item-has-focus')}
                onBlur={() => setItemFocusCssClass('')}
                onChange={(e: any) => {
                  setItemValueCssClass(!!e.detail.value ? 'item-has-value' : '');
                  const val = e.detail.value || '[]';
                  const c = JSON.parse(val).map((it: any) => {
                    return it.value;
                  }).join('\n');
                  onChange(c);
                }}
              />
            </div>
          ) : (
            <TInput
              value={value}
              onIonChange={onChange}
              multiline={multiline}
              aria-invalid={errors && errors[name] ? 'true' : 'false'}
              aria-describedby={`${name}Error`}
              {...(rest as any)}
            />
          )}
          defaultValue=""
          name={name}
          control={control}
        />
        {!!icon && (
          <IonIcon icon={icon} slot="end" />
        )}
      </IonItem>
      {errors && errors[name] && (
        <IonText color="danger" className="ion-padding-start t-error-message">
          <small>
            <span role="alert" id={`${name}Error`}>
              {errors[name].message.replace(/([A-Z])/g, ' $1').replace(/^./, (str: string) => str.toUpperCase())}
            </span>
          </small>
        </IonText>
      )}
    </>
  );
};

export default ControlledInput;
