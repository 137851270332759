import React, { useContext } from 'react';
import { AppPage } from '../pages';
import {
  IonItem,
  IonIcon,
  IonLabel,
  IonBadge,
  IonMenuToggle
} from '@ionic/react';
import { haptics } from '../core';
import { NotificationsContext } from '../context/NotificationsContext';
import { AppContext } from '../context/AppContext';
import css from 'classnames';
import { useInboundMetrics } from '../hooks';

export interface TMenuItemProps {
  page: AppPage;

  isSelected?: boolean;

  showSubmenu: (page: AppPage) => any;
}

const TMenuItem: React.FC<TMenuItemProps> = ({
  page,
  isSelected,
  showSubmenu
}) => {
  const notificationsContext = useContext(NotificationsContext);
  const appContext = useContext(AppContext);
  const { count: inboundCount } = useInboundMetrics();
  const badgeCount =
    page.showBadge && page.badgeCount
      ? page.badgeCount({
          ...appContext.state,
          ...notificationsContext.state,
          inboundCount
        })
      : 0;

    const badgeCount2 =
      page.showBadge && page.badgeCount2
        ? page.badgeCount2({
            ...appContext.state,
            ...notificationsContext.state,
            inboundCount
          })
        : 0;

  const item = (
    <IonItem
      lines="full"
      routerLink={page.url}
      routerDirection="none"
      onClick={() => {
        haptics.lightImpact();
        if (page.subpages) {
          showSubmenu(page);
        }
      }}
      detail
      className={css('ion-activatable pointer', {
        'menu-item-selected': isSelected
      })}
    >
      <IonIcon slot="start" icon={page.icon} />
      <IonLabel>{page.title}</IonLabel>
      {!!badgeCount2 && (
        <IonBadge mode="ios" color="orange">
          {badgeCount2}
        </IonBadge>
      )}
      {!!badgeCount && (
        <IonBadge mode="ios" color="secondary">
          {badgeCount}
        </IonBadge>
      )}
    </IonItem>
  );

  return page.subpages ? (
    item
  ) : (
    <IonMenuToggle autoHide={false}>{item}</IonMenuToggle>
  );
};

export default TMenuItem;
