import PagedService from './paged-service';
import { http } from '../core';
import qs from 'querystring';
import { QuickReply } from '../types/Shortcodes';

class QuickRepliesService extends PagedService {
  async list(params: any) {
    try {
      let query = '';
      if (params) {
        query = qs.stringify(params);
      }

      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: `/quick-replies/?${query}`
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async create(reply: Partial<QuickReply>) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'POST',
        url: `/quick-replies/`,
        data: reply
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async update(id: any, changes: Partial<QuickReply>) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'PATCH',
        url: `/quick-replies/${id}/`,
        data: changes
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  renderedMessageText(reply: QuickReply, lead: any) {
    if (!lead) {
      return reply.text;
    }

    const re = /\{\{([A-z ]*)\}\}/;
    let msg = reply.text;
    let match: any;
    while ((match = re.exec(msg))) {
      const variable = match[0];
      const key = match[1].trim();
      msg = msg.replace(variable, lead[key] ?? 'NO VALUE');
    }

    return msg;
  }

  get templateRegex() {
    return /\{\{([A-z ]*)$/;
  }
}

const quickRepliesService = new QuickRepliesService();
export default quickRepliesService;
