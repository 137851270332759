import React, { useContext, useState, useEffect } from 'react';
import TExpandableItemGroup from './TExpandableItemGroup';
import TSelectItem from './TSelectItem';
import { util } from '../core';
import { LeadContext } from '../context/LeadContext';
import { AppContext } from '../context/AppContext';
const LeadAssignees: React.FC = () => {
  const appContext = useContext(AppContext);
  const { state, updateAndSaveLead } = useContext(LeadContext);
  const { lead, clientUsers } = state;
  const [changes, setChanges] = useState(lead);
  const { user } = appContext.state;

  useEffect(() => setChanges(lead), [lead]);
  const updateLead = (key: string) => async (e: CustomEvent) => {
    const val = e.detail.value;
    if (
      ['assigned_to', 'bdc_rep', 'sales_manager'].includes(key)  &&
      val &&
      !clientUsers?.find((it: any) => it.id === val)
    ) {
      return;
    }
    await updateAndSaveLead(key, e, user);
  };

  if (state.loading) {
    return null;
  }

  return (
    <TExpandableItemGroup title='Assignees' defaultExpanded={true}>
      <TSelectItem
        label='Salesperson'
        labelPosition='stacked'
        onChange={updateLead('assigned_to')}
        value={changes?.assigned_to}
        options={[
          { value: null, text: 'Unassigned' },
          ...util.sortByProp(clientUsers, 'full_name').map((it: any) => ({
            value: it.id,
            text: it.full_name,
          })),
        ]}
      />
      <TSelectItem
        label='Sales Manager'
        labelPosition='stacked'
        onChange={updateLead('sales_manager')}
        value={changes?.sales_manager}
        options={[
          { value: null, text: 'Unassigned' },
          ...util.sortByProp(clientUsers, 'full_name').map((it: any) => ({
            value: it.id,
            text: it.full_name,
          })),
        ]}
      />
      <TSelectItem
        label='BDC Rep'
        labelPosition='stacked'
        onChange={updateLead('bdc_rep')}
        value={changes?.bdc_rep}
        options={[
          { value: null, text: 'Unassigned' },
          ...util.sortByProp(clientUsers, 'full_name').map((it: any) => ({
            value: it.id,
            text: it.full_name,
          })),
        ]}
      />
    </TExpandableItemGroup>
  );
};

export default LeadAssignees;
