import { http } from '../core';
import qs from 'querystring';

class NeedsCallService {
  aggregateReport(rpt: any, clients: any) {
    const summedReport = {
      name: '',
      wait_duration: 0,
      stillWaiting: 0
    } as any;
    const hasDuration = rpt.filter((r: any) => r.wait_duration !== null);
    rpt.forEach((it: any) => {
      summedReport.name = clients.find((c:any)=> c.id === it.client)?.name;
      if (it.wait_duration === null) {
        summedReport.stillWaiting += 1
      } else {
        summedReport.wait_duration += it.wait_duration;
      }
    });
    summedReport.avgWaitDuraton = hasDuration.length ? summedReport.wait_duration/hasDuration.length : 0;
    summedReport.count = rpt.length;
    summedReport.hasDuration = hasDuration.length;
    summedReport.stillWaiting = rpt.length - hasDuration.length;
    return summedReport;
  }

  async retrieveReport(
    from: Date,
    to: Date,
    clientId: number,
    waitDurationIsNull?: boolean,
    isManual?: boolean
  ) {
    try {
      const params = {
        created_at__gte: from.toISOString(),
        created_at__lte: to.toISOString(),
        is_manual: isManual,
        wait_duration__isnull: waitDurationIsNull
      } as any;
      const query = qs.stringify(params);
      const { data } = await http.authorizedRequest({
        url: `/clients/${clientId}/needs-call/?${query}`,
        exclusive: false
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

}
const needsCallService = new NeedsCallService();
export default needsCallService;
