import React, { useContext } from 'react';
import { LeadContext } from '../context/LeadContext';
import TExpandableItemGroup from './TExpandableItemGroup';
import { TTextItem } from '.';
import { AppContext } from '../context/AppContext';

const LeadOtherFields: React.FC = () => {
  const appContext = useContext(AppContext);
  const { clientProviders } = appContext.state;
  const { state } = useContext(LeadContext);

  const { lead } = state;
  const provider = clientProviders?.find(it => it.id === lead?.master_provider)
    ?.name;
  return (
    <TExpandableItemGroup title="Other">
      <TTextItem
        label="Lead Provider"
        text={provider}
        labelPosition="stacked"
      />
      <TTextItem
        label="TECOBI Number"
        text={lead?.twilio_number_str}
        labelPosition="stacked"
      />
      <TTextItem
        label="Total Messages"
        text={lead?.lead_sms_count}
        labelPosition="stacked"
      />
    </TExpandableItemGroup>
  );
};

export default LeadOtherFields;
