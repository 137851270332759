import { Deploy } from 'cordova-plugin-ionic';
import native from './native';
import appNotification from './app-notifications';
import sentry from './sentry';
import * as serviceWorker from '../serviceWorker';
import { loadingIndicator } from '../core';
import environment from './environment';

class Updates {
  private get updateMethod() {
    if (environment.isProduction) {
      return 'background';
    }

    return 'none';
  }

  async currentVersion() {
    if (native.isNative && environment.isProduction) {
      try {
        return await Deploy.getCurrentVersion();
      } catch (e) {
        console.error(e);
      }
    }
  }

  async currentConfig() {
    if (native.isNative && environment.isProduction) {
      try {
        return await Deploy.getConfiguration();
      } catch (e) {
        console.error(e);
      }
    }
  }

  async update(progressHandler?: (i?: number) => any | undefined) {
    if (native.isNative && environment.isProduction) {
      try {
        return await Deploy.sync(
          { updateMethod: 'background' },
          progressHandler
        );
      } catch (e) {
        console.error(e);
      }
    }
  }

  async reload() {
    if (native.isNative) {
      try {
        await Deploy.reloadApp();
      } catch (e) {
        console.error(e);
      }
    }
  }

  async updateChannel(channel: string) {
    if (native.isNative) {
      try {
        await Deploy.configure({ channel, updateMethod: this.updateMethod });
      } catch (e) {
        console.error(e);
      }
    }
  }

  async isUpdateAvailable() {
    if (native.isNative && environment.isProduction) {
      try {
        const r = await Deploy.checkForUpdate();
        return r.available;
      } catch (e) {
        appNotification.toast('Failed to check for update.', 'Updates');
        console.log(e);
      }
    }

    return false;
  }

  async unregisterServiceWorker(reload = true) {
    if (navigator.serviceWorker) {
      const sw = await navigator.serviceWorker.getRegistration(
        '/service-worker.js'
      );

      if (sw) {
        await sw.unregister();
      }

      if (reload) {
        window.location.reload();
      }
    }
  }

  skipWaiting(worker: any) {
    //auto reload if it takes longer than 20s
    setTimeout(() => {
      window.location.reload();
    }, 20000);

    setInterval(() => {
      if (worker.state === 'activated') {
        window.location.reload();
      } else {
        worker.postMessage({
          type: 'SKIP_WAITING'
        });
      }
    }, 1000);
  }

  initialize(user: any) {
    if (environment.isProduction) {
      console.info('[UPDATES] INIT', {
        n: native.isNative,
        u: !!user
      });
    }

    if (native.isNative || !user) {
      serviceWorker.unregister();
      this.unregisterServiceWorker(false);
    } else {
      serviceWorker.register({
        onUpdateAvailable: (
          installingWorker: ServiceWorker,
          registration: ServiceWorkerRegistration,
          version: any
        ) => {
          appNotification.toast(
            'There is a new version available.',
            'Update Available',
            {
              duration: 0,
              buttons: [
                {
                  text: 'Update',
                  handler: () => {
                    this.skipWaiting(installingWorker);
                    loadingIndicator.create();
                  }
                },
                { text: 'Later', role: 'cancel' }
              ]
            }
          );
        }
      });
    }
  }

  async load() {
    let data = {} as any;
    if (native.isNative) {
      data = {
        currentVersion: await this.currentVersion(),
        updateConfig: await this.currentConfig()
      };
    }

    sentry.initialize();

    return data;
  }
}

const updates = new Updates();
export default updates;
