import React, { useCallback, useState, useEffect, useContext } from 'react';
import { callOutline, mailOutline } from 'ionicons/icons';
import { archivedLeadsService } from '../services';

import {
  IonCol,
  IonRow,
  IonCard,
  IonIcon,
  IonText,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonCardContent,
  IonList,
  IonItem,
  IonBadge,
  IonRouterLink,
  IonGrid,
  IonSkeletonText,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';

import { useHistory } from 'react-router-dom';

import {
  TPage,
  UpcomingAppointmentsCard,
  UnreadNotificationsCard,
  TimeToCallCard,
  Leaderboard,
  UnassignedLeadsCountCard,
  SmsUsageStats,
} from '../components';

import { leadsService } from '../services';

import { AppContext } from '../context/AppContext';
import { LeadContext } from '../context/LeadContext';

function formatPhoneNumber(phoneNumberString: string) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, ''); // remove all non-numeric characters

  // Try to match the 11-digit format first
  let match = cleaned.match(/^1(\d{3})(\d{3})(\d{4})$/); // 1, followed by groups of 3, 3, and 4 digits
  if (!match) {
    // If no match, try the 10-digit format
    match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  }

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumberString; // return original string if no match
}

let loadWaitingLeadsInterval: any;

const Dashboard: React.FC = () => {
  const history = useHistory();
  const [needsCall, setNeedsCall] = useState<any>();
  const [usersNeedsCall, setUsersNeedsCall] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [usersWaiting, setUsersWaiting] = useState<any>();
  const [newLeadsToday, setNewLeadsToday] = useState<any>();
  const [newInboundsToday, setNewInboundsToday] = useState<any>();
  const [workingPipeline, setWorkingPipeline] = useState<any>();
  const [pausedPipeline, setPausedPipeline] = useState<any>();
  const [deadGhostPipeline, setDeadGhostPipeline] = useState<any>();
  const [waiting, setWaiting] = useState<any>();
  const [archivedLeadsToday, setArchivedLeadsToday] = useState<any>();
  const { state } = useContext(AppContext);
  const { dispatch } = useContext(LeadContext);
  const loadWaitingLeads = useCallback(async () => {
    const [
      needsCall,
      waiting,
      archivedLeadsToday,
      newLeadsToday,
      newInboundsToday,
      workingPipeline,
      pausedPipeline,
      deadGhostPipeline,
    ] = await Promise.all([
      leadsService.listNeedsCall({
        clientId: state.selectedClientId,
        pageSize: 500,
      }),
      leadsService.listWaiting({
        clientId: state.selectedClientId,
        pageSize: 500,
      }),
      archivedLeadsService.listArchivedLeadsToday(state.selectedClientId),
      leadsService.listNewToday({
        clientId: state.selectedClientId,
        pageSize: 1,
      }),
      leadsService.listHumanInboundsToday({
        clientId: state.selectedClientId,
        pageSize: 1,
      }),
      leadsService.listWorkingPipeline({
        clientId: state.selectedClientId,
        pageSize: 1,
      }),
      leadsService.listPausedPipeline({
        clientId: state.selectedClientId,
        pageSize: 1,
      }),
      leadsService.listDeadGhostPipeline({
        clientId: state.selectedClientId,
        pageSize: 1,
      }),
    ]);
    const usersWaiting = {} as any;
    const usersNeedsCall = {} as any;
    needsCall.results.forEach((it: any) => {
      const userFullName: string = `${it.assigned_to?.first_name} ${it.assigned_to?.last_name}::${it.assigned_to?.id}`;
      if (!(userFullName in usersNeedsCall)) {
        usersNeedsCall[userFullName] = needsCall.results.filter(
          (o: any) => o.assigned_to?.id === it.assigned_to?.id
        );
      }
    });

    waiting.results.forEach((it: any) => {
      const userFullName: string = `${it.assigned_to?.first_name} ${it.assigned_to?.last_name}::${it.assigned_to?.id}`;
      if (!(userFullName in usersWaiting)) {
        usersWaiting[userFullName] = waiting.results.filter(
          (o: any) => o.assigned_to?.id === it.assigned_to?.id
        );
      }
    });

    setUsersNeedsCall(usersNeedsCall);
    setUsersWaiting(usersWaiting);
    setNeedsCall(needsCall);
    setWaiting(waiting);
    setArchivedLeadsToday(archivedLeadsToday);
    setNewLeadsToday(newLeadsToday);
    setNewInboundsToday(newInboundsToday);
    setWorkingPipeline(workingPipeline);
    setPausedPipeline(pausedPipeline);
    setDeadGhostPipeline(deadGhostPipeline);
    setLoading(false);
  }, [state.selectedClientId]);

  useEffect(() => {
    clearInterval(loadWaitingLeadsInterval);
    setLoading(true);
    loadWaitingLeads();
    loadWaitingLeadsInterval = setInterval(loadWaitingLeads, 10000);
  }, [loadWaitingLeads, state.selectedClientId]);

  useIonViewWillEnter(() => {
    clearInterval(loadWaitingLeadsInterval);
    setLoading(true);
    loadWaitingLeads();
    loadWaitingLeadsInterval = setInterval(loadWaitingLeads, 10000);
  }, [state.selectedClientId]);

  useIonViewWillLeave(() => {
    clearInterval(loadWaitingLeadsInterval);
  });

  const renderUserList = (o: any, listType: string) => {
    const keys: any = typeof o === 'object' ? Object.keys(o) : [];
    if (!keys.length) {
      return (
        <IonItem lines='none'>
          <span role='img' aria-label='cool'>
            😎
          </span>{' '}
          &nbsp;No leads waiting.
        </IonItem>
      );
    }
    return keys.map((key: string) => {
      const keyPcs = key.split('::');
      const userFullName = keyPcs[1] !== 'undefined' ? keyPcs[0] : 'Unassigned';
      const userId = keyPcs[1] !== 'undefined' ? keyPcs[1] : 'Unassigned';
      return (
        <IonItem
          className='pointer'
          key={key}
          routerLink='/text-messages/'
          routerDirection='none'
          onClick={() => {
            const changes = {
              assigned_to: {
                id: userId,
                display: userFullName,
              },
              convo_archived: 'Active',
            } as any;
            if (listType === 'needsCall') {
              changes['needs_call'] = 'Call Needed';
            } else if (listType === 'waiting') {
              changes['last_message_type'] = 'inbound';
            }
            dispatch({
              type: 'set',
              value: {
                filters: { ...changes },
              },
            });
          }}
          detail
        >
          {userFullName}
          <IonBadge
            color={listType === 'waiting' ? 'secondary' : 'orange'}
            slot='end'
          >
            {o[key]?.length}
          </IonBadge>
        </IonItem>
      );
    });
  };
  return (
    <TPage loading={false}>
      <IonGrid>
        <IonRow>
          <IonCol sizeSm='12' sizeMd='6'>
            <Leaderboard
              title='Sales Leaderboard'
              userType='assigned_to'
              limit={5}
              showSeeMore={true}
              selectedClientId={state.selectedClientId}
            />
          </IonCol>
          <IonCol sizeSm='12' sizeMd='6'>
            {!!state.selectedClient?.package?.included &&
              !!state.selectedClient?.package?.overage && (
                <SmsUsageStats selectedClientId={state.selectedClientId} />
              )}
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Contact Support</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                {/* Email Support */}
                <div className='dashboard-contact-details-container'>
                  <IonIcon icon={mailOutline} size='large' />
                  <IonText className='dashboard-contact-details'>
                    Email us at:
                    <a href='mailto:support@tecobi.com'>support@tecobi.com</a>
                  </IonText>
                </div>
                {/* Text Support */}
                {state?.user?.tecobi_lead_twilio_number && (
                  <div
                    className='dashboard-contact-details-container'
                    style={{ marginTop: '16px' }}
                  >
                    <IonIcon icon={callOutline} size='large' />
                    <IonText className='dashboard-contact-details'>
                      Text us at:
                      <a
                        href={`sms:${state?.user?.tecobi_lead_twilio_number}?body=Help TECOBI!`}
                      >
                        {formatPhoneNumber(
                          state?.user?.tecobi_lead_twilio_number
                        )}
                      </a>
                    </IonText>
                  </div>
                )}
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid className='reports'>
        <IonRow>
          <IonCol sizeSm='12' sizeXs='12' sizeMd='6' sizeLg='6' sizeXl='6'>
            <IonCard>
              <IonCardHeader color='tertiary'>
                <IonCardTitle>Today</IonCardTitle>
              </IonCardHeader>
              <IonCardContent style={{ maxHeight: 300, overflow: 'auto' }}>
                {loading ? (
                  Array.from(Array(3).keys()).map((it: any, i: number) => (
                    <IonItem key={i}>
                      <IonLabel>
                        <IonSkeletonText key={i} animated />
                      </IonLabel>
                    </IonItem>
                  ))
                ) : (
                  <IonList>
                    <IonItem
                      className='pointer'
                      routerLink='/text-messages/'
                      routerDirection='none'
                      onClick={() => {
                        const changes = {
                          convo_archived: 'Active',
                          created_at__gte: new Date().toISOString(),
                          created_at__lte: new Date().toISOString(),
                        } as any;
                        dispatch({
                          type: 'set',
                          value: {
                            filters: { ...changes },
                          },
                        });
                        history.push('/text-messages/');
                      }}
                      detail
                    >
                      New Leads
                      <IonBadge color={'tertiary'} slot='end'>
                        {newLeadsToday?.count}
                      </IonBadge>
                    </IonItem>
                    <IonItem
                      className='pointer'
                      routerLink='/text-messages/'
                      routerDirection='none'
                      onClick={() => {
                        const changes = {
                          convo_archived: 'Active',
                          last_human_inbound_time__gte:
                            new Date().toISOString(),
                          last_human_inbound_time__lte:
                            new Date().toISOString(),
                        } as any;
                        dispatch({
                          type: 'set',
                          value: {
                            filters: { ...changes },
                          },
                        });
                        history.push('/text-messages/');
                      }}
                      detail
                    >
                      Engaged Leads
                      <IonBadge color={'tertiary'} slot='end'>
                        {newInboundsToday?.count}
                      </IonBadge>
                    </IonItem>
                    <IonItem
                      className='pointer'
                      routerLink='/reports/archived-leads/'
                      routerDirection='none'
                      onClick={() => {
                        history.push('/reports/archived-leads/');
                      }}
                      detail
                    >
                      Archived Leads
                      <IonBadge color={'tertiary'} slot='end'>
                        {archivedLeadsToday?.length}
                      </IonBadge>
                    </IonItem>
                  </IonList>
                )}
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol sizeSm='12' sizeXs='12' sizeMd='6' sizeLg='6' sizeXl='6'>
            <IonCard>
              <IonCardHeader color='success'>
                <IonCardTitle>Pipelines</IonCardTitle>
              </IonCardHeader>
              <IonCardContent style={{ maxHeight: 300, overflow: 'auto' }}>
                {loading ? (
                  Array.from(Array(3).keys()).map((it: any, i: number) => (
                    <IonItem key={i}>
                      <IonLabel>
                        <IonSkeletonText key={i} animated />
                      </IonLabel>
                    </IonItem>
                  ))
                ) : (
                  <IonList>
                    <IonItem
                      className='pointer'
                      routerLink='/text-messages/'
                      routerDirection='none'
                      onClick={() => {
                        const changes = {
                          convo_archived: 'Active',
                          lead_type__in: 'SMS,Web,Other,ADF,DMS',
                          classification: 'Sales',
                          pause_date: 'Unpaused',
                          imported_replied: '2',
                          violators: false,
                          sms_optout: false,
                          carrier_type__in: 'null,mobile,voip',
                          status__in: '1,2,23,21,11,12,35',
                        } as any;
                        dispatch({
                          type: 'set',
                          value: {
                            filters: { ...changes },
                          },
                        });
                        history.push('/text-messages/');
                      }}
                      detail
                    >
                      Working Pipeline
                      <IonBadge color={'success'} slot='end'>
                        {workingPipeline?.count}
                      </IonBadge>
                    </IonItem>
                    <IonItem
                      className='pointer'
                      routerLink='/text-messages/'
                      routerDirection='none'
                      onClick={() => {
                        const changes = {
                          convo_archived: 'Active',
                          pause_date: 'Paused',
                        } as any;
                        dispatch({
                          type: 'set',
                          value: {
                            filters: { ...changes },
                          },
                        });
                        history.push('/text-messages/');
                      }}
                      detail
                    >
                      Paused Pipeline
                      <IonBadge color={'success'} slot='end'>
                        {pausedPipeline?.count}
                      </IonBadge>
                    </IonItem>
                    <IonItem
                      className='pointer'
                      routerLink='/text-messages/'
                      routerDirection='none'
                      onClick={() => {
                        const changes = {
                          convo_archived: 'Active',
                          lead_type__in: 'SMS,Web,Other,ADF,DMS',
                          classification: 'Sales',
                          pause_date: 'Unpaused',
                          imported_replied: '2',
                          violators: false,
                          sms_optout: false,
                          carrier_type__in: 'null,mobile,voip',
                          status__in: '28',
                        } as any;
                        dispatch({
                          type: 'set',
                          value: {
                            filters: { ...changes },
                          },
                        });
                        history.push('/text-messages/');
                      }}
                      detail
                    >
                      Dead-Ghost Pipeline
                      <IonBadge color={'success'} slot='end'>
                        {deadGhostPipeline?.count}
                      </IonBadge>
                    </IonItem>
                  </IonList>
                )}
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid className='reports'>
        <IonRow>
          <IonCol sizeSm='12' sizeXs='12' sizeMd='6' sizeLg='6' sizeXl='6'>
            <IonCard>
              <IonCardHeader color='orange'>
                <IonCardTitle>
                  Customers Needing Call
                  <IonRouterLink
                    routerLink='/text-messages/'
                    routerDirection='root'
                  >
                    <IonBadge
                      className='pointer'
                      onClick={() => {
                        const changes = {
                          convo_archived: 'Active',
                          needs_call: 'Call Needed',
                        } as any;
                        dispatch({
                          type: 'set',
                          value: {
                            filters: { ...changes },
                          },
                        });
                      }}
                    >
                      {needsCall?.count}
                    </IonBadge>
                  </IonRouterLink>
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent style={{ maxHeight: 300, overflow: 'auto' }}>
                {loading ? (
                  Array.from(Array(3).keys()).map((it: any, i: number) => (
                    <IonItem key={i}>
                      <IonLabel>
                        <IonSkeletonText key={i} animated />
                      </IonLabel>
                    </IonItem>
                  ))
                ) : (
                  <IonList>
                    {renderUserList(usersNeedsCall, 'needsCall')}
                  </IonList>
                )}
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol sizeSm='12' sizeXs='12' sizeMd='6' sizeLg='6' sizeXl='6'>
            <IonCard>
              <IonCardHeader color='secondary'>
                <IonCardTitle>
                  Customers Waiting for SMS
                  <IonRouterLink
                    routerLink='/text-messages/'
                    routerDirection='root'
                  >
                    <IonBadge
                      className='pointer'
                      onClick={() => {
                        const changes = {
                          convo_archived: 'Active',
                          pause_date: 'Unpaused',
                          last_message_type: 'inbound',
                        } as any;
                        dispatch({
                          type: 'set',
                          value: {
                            filters: { ...changes },
                          },
                        });
                      }}
                    >
                      {waiting?.count}
                    </IonBadge>
                  </IonRouterLink>
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent style={{ maxHeight: 300, overflow: 'auto' }}>
                {loading ? (
                  Array.from(Array(3).keys()).map((it: any, i: number) => (
                    <IonItem key={i}>
                      <IonLabel>
                        <IonSkeletonText key={i} animated />
                      </IonLabel>
                    </IonItem>
                  ))
                ) : (
                  <IonList>{renderUserList(usersWaiting, 'waiting')}</IonList>
                )}
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid className='reports'>
        <IonRow>
          <UpcomingAppointmentsCard />
        </IonRow>
      </IonGrid>

      <TimeToCallCard />
      <UnassignedLeadsCountCard />
      <IonGrid className='reports'>
        <IonRow>
          <IonCol sizeSm='12' sizeXs='12' sizeMd='12' sizeLg='12' sizeXl='12'>
            <UnreadNotificationsCard />
          </IonCol>
        </IonRow>
      </IonGrid>
    </TPage>
  );
};

export default Dashboard;
