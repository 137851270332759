import { http } from '../core';
import qs from 'qs';

class TopEverService {
  async list(params: any) {
    try {
      const query = qs.stringify(params);
      const res = await http.authorizedRequest({
        method: 'GET',
        url: `/top-ever/?${query}`
      });

      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

const topEverService = new TopEverService();
export default topEverService;
