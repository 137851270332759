import React, { useContext, useCallback, useState } from 'react';
import { AppContext } from '../context/AppContext';
import { TPage, HapticButton, ButtonGrid, MetricCard } from '../components';
import { NinjaTeamClientQueueCountsModal } from '../components/modals'
import {
  IonItem,
  IonIcon,
  IonLabel,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonList,
  IonBadge,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonSkeletonText,
  useIonViewWillEnter,
  useIonViewWillLeave
} from '@ionic/react';
import { time, alarm, carSport, call as callIcon } from 'ionicons/icons';
import { util, appNotification, loadingIndicator, http } from '../core';
import {
  userService,
  inboundLeadsService,
  allAppointmentsService,
  creditAppStatsService,
  tradeinStatsService,
  topEverService
} from '../services';
import { useHistory } from 'react-router-dom';
import { useInboundMetrics, useDarkTheme } from '../hooks';
import DataTable from 'react-data-table-component';
import moment from 'moment';

const columns = [
  {
    name: 'Name',
    selector: (row: any) => row.teamName,
    sortable: true,
  },
  {
    name: 'Set Today',
    selector: (row: any) => row.setsToday,
    sortable: true,
    right: true
  },
  {
    name: 'Set Yesterday',
    selector: (row: any) => row.setsYesterday,
    sortable: true,
    right: true
  },
  {
    name: 'Most Ever Set',
    selector: (row: any) => row.topEverSet,
    sortable: true,
    right: true
  },
  {
    name: 'Sold Today',
    selector: (row: any) => row.soldsToday,
    sortable: true,
    right: true
  },
  {
    name: 'Sold Yesterday',
    selector: (row: any) => row.soldsYesterday,
    sortable: true,
    right: true
  },
  {
    name: 'Most Ever Sold',
    selector: (row: any) => row.topEverSold,
    sortable: true,
    right: true
  },
  {
    name: 'Trades Today',
    selector: (row: any) => row.tradeinsToday,
    sortable: true,
    right: true
  },
  {
    name: 'Trades Yesterday',
    selector: (row: any) => row.tradeinsYesterday,
    sortable: true,
    right: true
  },
  {
    name: 'Most Trades Ever',
    selector: (row: any) => row.topEverTradeins,
    sortable: true,
    right: true
  },
  {
    name: 'Credit Apps Today',
    selector: (row: any) => row.creditAppsToday,
    sortable: true,
    right: true
  },
  {
    name: 'Credit Apps Yesterday',
    selector: (row: any) => row.creditAppsYesterday,
    sortable: true,
    right: true
  },
  {
    name: 'Max Credit Apps Ever',
    selector: (row: any) => row.topEverCreditApps,
    sortable: true,
    right: true
  },
];

const InboundQueuePage: React.FC = () => {
  util.createTecobiDarkDataTableTheme();
  const { state } = useContext(AppContext);
  const { isClockedIn, user, ninjaTeams } = state;
  const { count: inboundCount, needsCallCount, needsTextCount } = useInboundMetrics();
  const history = useHistory();
  const isDark = useDarkTheme();
  const [ninjaTeamQueueCounts, setNinjaTeamQueueCounts] = useState<any>([]);
  const [appointmentsSetToday, setAppointmentsSetToday] = useState<any>([]);
  const [appointmentsSoldToday, setAppointmentsSoldToday] = useState<any>([]);
  const [tradeinsToday, setTradeinsToday] = useState<any>([]);
  const [tradeinsYesterday, setTradeinsYesterday] = useState<any>([]);
  const [creditAppsToday, setCreditAppsToday] = useState<any>([]);
  const [creditAppsYesterday, setCreditAppsYesterday] = useState<any>([]);
  const [appointmentsSetYesterday, setAppointmentsSetYesterday] = useState<any>([]);
  const [appointmentsSoldYesterday, setAppointmentsSoldYesterday] = useState<any>([]);
  const [topEver, setTopEver] = useState<any>([]);
  const [showTeamClientQueueCountModal, setShowTeamClientQueueCountModal] = useState<any>();
  const [teamApptData, setTeamApptData] = useState<any>([]);
  const updateClockedInStatus = useCallback(() => {
    userService.toggleClockIn(isClockedIn);
  }, [isClockedIn]);

  const loadAppointmentCounts = useCallback(async () => {
    const todayStart = moment().startOf('day');
    const todayEnd = moment().endOf('day');
    const yesterdayStart = moment().startOf('day').add(-1, 'days');
    const yesterdayEnd = moment().startOf('day').add(-1, 'milliseconds');
    const distinct = true;
    const [
      appointmentsSetToday,
      appointmentsSoldToday,
      appointmentsSetYesterday,
      appointmentsSoldYesterday,
      creditAppsToday,
      creditAppsYesterday,
      tradeinsToday,
      tradeinsYesterday,
      topEver
    ] = await Promise.all([
      allAppointmentsService.list({created_at__gte: todayStart.toISOString(), created_at__lte: todayEnd.toISOString(), distinct}),
      allAppointmentsService.list({sold__gte: todayStart.toISOString(), sold__lte: todayEnd.toISOString(), distinct}),
      allAppointmentsService.list({created_at__gte: yesterdayStart.toISOString(), created_at__lte: yesterdayEnd.toISOString(), distinct}),
      allAppointmentsService.list({sold__gte: yesterdayStart.toISOString(), sold__lte: yesterdayEnd.toISOString(), distinct}),
      creditAppStatsService.list({completed_at__gte: todayStart.toISOString(), completed_at__lte: todayEnd.toISOString(), distinct}),
      creditAppStatsService.list({completed_at__gte: yesterdayStart.toISOString(), completed_at__lte: yesterdayEnd.toISOString(), distinct}),
      tradeinStatsService.list({created_at__gte: todayStart.toISOString(), created_at__lte: todayEnd.toISOString(), trade_pending_id__isnull: false, distinct}),
      tradeinStatsService.list({created_at__gte: yesterdayStart.toISOString(), created_at__lte: yesterdayEnd.toISOString(), trade_pending_id__isnull: false, distinct}),
      topEverService.list({created_at__gte: moment().add(-1, 'day').toISOString()})
    ])
    setAppointmentsSetToday(appointmentsSetToday);
    setAppointmentsSetYesterday(appointmentsSetYesterday);
    setAppointmentsSoldToday(appointmentsSoldToday);
    setAppointmentsSoldYesterday(appointmentsSoldYesterday);
    setCreditAppsToday(creditAppsToday);
    setCreditAppsYesterday(creditAppsYesterday);
    setTradeinsToday(tradeinsToday);
    setTradeinsYesterday(tradeinsYesterday);
    setTopEver(topEver);
    const teamApptData = [] as any;
    ninjaTeams.forEach((t: any) => {
      const teamTopEver = topEver.find((it: any) => it.ninja_team === t.id);
      const o: any = {
        teamName: t.name,
        setsToday: appointmentsSetToday.filter((it: any) => it.team_id === t.id).length,
        setsYesterday: appointmentsSetYesterday.filter((it: any) => it.team_id === t.id).length,
        soldsToday: appointmentsSoldToday.filter((it: any) => it.team_id === t.id).length,
        soldsYesterday: appointmentsSoldYesterday.filter((it: any) => it.team_id === t.id).length,
        creditAppsToday: creditAppsToday.filter((it: any) => it.team_id === t.id).length,
        creditAppsYesterday: creditAppsYesterday.filter((it: any) => it.team_id === t.id).length,
        tradeinsToday: tradeinsToday.filter((it: any) => it.team_id === t.id).length,
        tradeinsYesterday: tradeinsYesterday.filter((it: any) => it.team_id === t.id).length,
        topEverSold: teamTopEver?.solds_count,
        topEverSet: teamTopEver?.sets_count,
        topEverTradeins: teamTopEver?.tradeins_count,
        topEverCreditApps: teamTopEver?.credit_apps_count
      };
      teamApptData.push(o);
    });
    setTeamApptData(teamApptData);
  }, [ninjaTeams]);

  const loadNinjaTeamQueueCounts = useCallback(async () => {
    const [teamQueueCounts, teamNeedsCallQueueCounts] = await Promise.all([
      inboundLeadsService.getNinjaTeamQueueCounts(),
      inboundLeadsService.getNinjaTeamNeedsCallQueueCounts()
    ])
    teamQueueCounts.map((it: any) => {
      const needsCallItem: any = teamNeedsCallQueueCounts.find((item: any) => item.team_name === it.team_name);
      it.needsCallCount =  !!needsCallItem ? needsCallItem.count : 0;
      it.id = ninjaTeams.find((item: any) => it.team_name === item.name)?.id;
      return it;
    })
    setNinjaTeamQueueCounts(teamQueueCounts);
  }, [ninjaTeams]);

  let loadNinjaTeamQueueCountsInterval: any;
  let loadAppointmentCountsInterval: any;

  useIonViewWillEnter(() => {
    loadNinjaTeamQueueCountsInterval = setInterval(loadNinjaTeamQueueCounts, 20000);
    loadNinjaTeamQueueCounts();
    loadAppointmentCountsInterval = setInterval(loadAppointmentCounts, 60000);
    loadAppointmentCounts();
  });

  useIonViewWillLeave(() => {
    clearInterval(loadNinjaTeamQueueCountsInterval);
    clearInterval(loadAppointmentCountsInterval);
  });

  const doNinjitsu = async () => {
    if (!isClockedIn) {
      return updateClockedInStatus();
    }

    try {
      await loadingIndicator.create(`Getting next lead...`);
      const lead = await inboundLeadsService.getNextInboundLead(user);
      if (lead) {
        history.push(
          `/inbound-queue/conversation/${lead.client_id}/${lead.id}/`
        );
      } else {
        appNotification.toast('No leads found.', 'Error');
      }
    } catch (e) {
      http.onHttpError(e);
    } finally {
      await loadingIndicator.dismiss();
    }
  };

  const doNeedsCallNinjitsu = async () => {
    if (!isClockedIn) {
      return updateClockedInStatus();
    }

    try {
      await loadingIndicator.create(`Getting next lead...`);
      const lead = await inboundLeadsService.getNextInboundLead(user, undefined, true);
      if (lead) {
        history.push(
          `/needs-call/conversation/${lead.client_id}/${lead.id}/`
        );
      } else {
        appNotification.toast('No leads found.', 'Error');
      }
    } catch (e) {
      http.onHttpError(e);
    } finally {
      await loadingIndicator.dismiss();
    }
  };

  return (
    <TPage
      loading={false}
      headerTool={
        <HapticButton
          icon={time}
          slot="end"
          onClick={updateClockedInStatus}
          color={isClockedIn ? 'success' : 'danger'}
          title={
            isClockedIn
              ? 'You are clocked in, click to clock out.'
              : 'You are not clocked in, click to clock in'
          }
        />
      }
      preContent={
        <IonItem lines="full" className="inbound">
          <IonIcon icon={alarm} slot="start" />
          <IonLabel className="ion-text-center">
            <strong>
              {inboundCount} {util.getPluralizedString('lead', inboundCount)}{' '}
              waiting...
            </strong>
          </IonLabel>
        </IonItem>
      }
    >
      <IonGrid className="reports">
        <IonRow>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="12" sizeLg="12" sizeXl="12">
            <IonCard className="top-accounts">
              <IonCardHeader>
                <IonCardTitle>Team Queue Size</IonCardTitle>
              </IonCardHeader>
              <IonCardContent className="no-padding">
                <IonList lines="full" className="no-padding no-margin">
                  <ButtonGrid>
                    <HapticButton
                      mode="ios"
                      size="small"
                      fill="solid"
                      expand="block"
                      color="secondary"
                      icon={carSport}
                      onClick={doNinjitsu}
                    >
                      Get Started
                      <IonBadge color="primary" mode="ios">
                        {needsTextCount}
                      </IonBadge>
                    </HapticButton>
                    <HapticButton
                      mode="ios"
                      size="small"
                      fill="solid"
                      expand="block"
                      color="tertiary"
                      disabled={!user.is_phone_ninja && !user.is_manager}
                      icon={callIcon}
                      onClick={doNeedsCallNinjitsu}
                    >
                      Start Calling
                      <IonBadge color="primary" mode="ios">
                        {needsCallCount}
                      </IonBadge>
                    </HapticButton>
                  </ButtonGrid>
                  {!ninjaTeamQueueCounts?.length && (
                    <IonItem>
                      <IonLabel>
                        <IonSkeletonText animated style={{ width: '60%' }} />
                        <IonSkeletonText animated />
                        <IonSkeletonText animated style={{ width: '88%' }} />
                        <IonSkeletonText animated style={{ width: '70%' }} />
                        <IonSkeletonText animated style={{ width: '60%' }} />
                      </IonLabel>
                    </IonItem>
                  )}
                  {ninjaTeamQueueCounts?.map((it: any, i: number) => (
                    <IonItem
                      key={`team-${i}`}
                      className="pointer"
                      onClick={() => setShowTeamClientQueueCountModal({clientId: it.id, title: it.team_name})}
                    >
                      <IonLabel>{it?.team_name}</IonLabel>
                      <IonBadge color="tertiary" mode="ios">
                        {it?.needsCallCount}
                      </IonBadge>
                      <IonBadge color="secondary" mode="ios">
                        {it?.count}
                      </IonBadge>
                    </IonItem>
                  ))}
                </IonList>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
            <MetricCard
              title="Sold Today"
              value={appointmentsSoldToday?.length}
              uom="Appt"
            />
          </IonCol>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
            <MetricCard
              title="Sold Yesterday"
              value={appointmentsSoldYesterday?.length}
              uom="Appt"
            />
          </IonCol>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
            <MetricCard
              title="Most Ever Sold"
              value={topEver?.find((it: any) => it.ninja_team === null)?.solds_count}
              uom="Appt"
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
            <MetricCard
              title="Set Today"
              value={appointmentsSetToday?.length}
              uom="Appt"
            />
          </IonCol>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
            <MetricCard
              title="Set Yesterday"
              value={appointmentsSetYesterday?.length}
              uom="Appt"
            />
          </IonCol>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
            <MetricCard
              title="Most Ever Set"
              value={topEver?.find((it: any) => it.ninja_team === null)?.sets_count}
              uom="Appt"
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
            <MetricCard
              title="Credit Apps Today"
              value={creditAppsToday?.length}
              uom="App"
            />
          </IonCol>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
            <MetricCard
              title="Credit Apps Yesterday"
              value={creditAppsYesterday?.length}
              uom="App"
            />
          </IonCol>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
            <MetricCard
              title="Most Credit Apps Ever"
              value={topEver?.find((it: any) => it.ninja_team === null)?.credit_apps_count}
              uom="App"
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
            <MetricCard
              title="Trades Today"
              value={tradeinsToday?.length}
              uom="Trade"
            />
          </IonCol>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
            <MetricCard
              title="Trades Yesterday"
              value={tradeinsYesterday?.length}
              uom="Trade"
            />
          </IonCol>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
            <MetricCard
              title="Most Trades Ever"
              value={topEver?.find((it: any) => it.ninja_team === null)?.tradeins_count}
              uom="Trade"
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol sizeSm="12" sizeXs="12" sizeMd="12" sizeLg="12" sizeXl="12">
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Team Appointments</IonCardTitle>
              </IonCardHeader>
              <IonCardContent className="no-padding">
                <DataTable
                  noHeader={true}
                  columns={columns}
                  data={teamApptData}
                  theme={isDark ? 'tecobi-dark' : 'default'}
                  pagination={false}
                  paginationServer={false}
                  highlightOnHover={true}
                />
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
      {showTeamClientQueueCountModal && (
        <NinjaTeamClientQueueCountsModal
          clientId={showTeamClientQueueCountModal.clientId}
          onDidDismiss={() => setShowTeamClientQueueCountModal(undefined)}
          title={showTeamClientQueueCountModal.title}
        />
      )}
    </TPage>
  );
};

export default InboundQueuePage;
