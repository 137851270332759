import React, { useState, useContext, useRef, useEffect } from 'react';
import {
  IonContent,
  IonLoading,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonButton,
  IonLabel,
  IonItem,
  IonToast,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
} from '@ionic/react';
import { loginService } from '../services';
import { AppContext } from '../context/AppContext';
import { authorization, initialization } from '../core';
import http from '../core/http';
import { useRouter } from '../hooks';

const SecurityCodeVerifyPage: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const { otpData } = state;
  const router = useRouter();
  const otpInputRef = useRef<HTMLIonInputElement>(null);
  const userId = otpData.user_pk;
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const handleCodeChange = (e: any) => {
    setCode(e.target.value);
  };
  useEffect(() => {
    setTimeout(() => otpInputRef.current?.setFocus(), 500);
  }, []);
  // Login callback function
  const onLogin = async (access: string, refresh: string) => {
    await authorization.setToken(access, refresh);
    const initial = await initialization.onAuthorized();
    dispatch({
      type: 'set',
      value: Object.assign(
        {
          selectedClientId: await authorization.getSelectedClientId(),
          authorizationToken: access,
          authorized: true,
        },
        initial
      ),
    });
    const destinationUrl = window.localStorage.getItem('destinationUrl') ?? '/';
    router.replace(destinationUrl, {});
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await loginService.verifyOtp(code, userId);
      onLogin(response.access, response.refresh);
    } catch (e) {
      console.error(e);
      setMessage(http.getErrorMessage(e));
      setLoading(false);
    }
  };
  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await handleSubmit();
  };
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton defaultHref='/login' />
          </IonButtons>
          <IonTitle>Verify Security Code</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonGrid>
          <IonRow className='ion-justify-content-center ion-align-items-center'>
            <IonCol style={{ maxWidth: 600, margin: 'auto' }}>
              <p>
                We've sent a security code to your registered mobile number
                ending in {otpData.phone_last4} and email address{' '}
                {otpData.email_obscure}. Please enter that code below to
                continue.
              </p>
              <form onSubmit={handleFormSubmit}>
  <IonItem>
    <IonLabel>Security Code:</IonLabel>
    <IonInput
      type="text"
      name="code"
      inputmode="numeric"
      autocomplete="one-time-code"
      pattern="\d{6}"
      required
      value={code}
      ref={otpInputRef}
      onIonChange={handleCodeChange}
    />
  </IonItem>
  <IonButton
    type='submit'
    expand='full'
    disabled={loading}
  >
    Submit
  </IonButton>
</form>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonToast
        isOpen={!!message}
        position='top'
        header={'Error'}
        message={message}
        onDidDismiss={() => setMessage('')}
        buttons={[{ text: 'Dismiss', role: 'cancel' }]}
      />
      <IonLoading isOpen={loading} />
    </IonPage>
  );
};

export default SecurityCodeVerifyPage;
