import React, { useCallback, useState, useContext } from 'react';
import { TReportsPage } from '../components';
import DataTable from 'react-data-table-component';
import { archivedLeadsService } from '../services';
import { LeadListModal } from '../components/modals';
import { AppContext } from '../context/AppContext';

interface LoadDataParams {
    since: Date;
    until: Date;
}

interface Lead {
    id: number;
    first_name: string;
    last_name: string;
    event_time: string;
    client_id: number;
}

const ArchivedLeadsReport = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedLeads, setSelectedLeads] = useState<Lead[]>([]);
  const { state: appState } = useContext(AppContext);
  const { selectedClientId } = appState;
  const [reports, setReports] = useState([]);

  const loadData = useCallback(async ({ since, until }: LoadDataParams) => {
      // Format dates as strings
      const sinceStr = since.toISOString(); // or format it as needed
      const untilStr = until.toISOString(); // or format it as needed
      if (selectedClientId) {
          const data = await archivedLeadsService.getArchivedLeads(selectedClientId, sinceStr, untilStr);
          console.log(data)
          setReports(data);
          return { data };
      }
  }, [selectedClientId]);



  interface ReportRow {
    first_name: string;
    last_name: string;
    archived_leads: any[]; // Replace 'any' with a more specific type if possible
  }

  const columns = [
      { name: 'First', sortable: true, selector: (row: ReportRow) => row.first_name },
      { name: 'Last', sortable: true, selector: (row: ReportRow) => row.last_name },
      {
          name: 'Archived Leads',
          id: 'archivedLeads',
          sortable: true,
          selector: (row: ReportRow) => row.archived_leads.length,
          cell: (row: ReportRow) => (
              <div style={{ cursor: 'pointer' }} onClick={() => handleLeadClick(row)}>
                  {row?.archived_leads?.length}
              </div>
          ),
      },
  ];
  const handleLeadClick = (row: ReportRow) => {
      setSelectedLeads(row?.archived_leads);
      setShowModal(true);
  };

  return (
    <TReportsPage onLoadData={loadData} defaultDateRange={"Today"}>
      {({ state }) => (
        <>
          <DataTable
            title="Archived Leads Report"
            columns={columns}
            defaultSortFieldId="archivedLeads"
            defaultSortAsc={false}
            data={reports}
            pagination={reports?.length > 50}
            highlightOnHover={true}
          />
          {showModal && (
            <LeadListModal
                isOpen={true}
                leads={selectedLeads}
                title="Archived Leads"
                onDidDismiss={() => setShowModal(false)}
            />
          )}
        </>
      )}
    </TReportsPage>
  );
};

export default ArchivedLeadsReport;
