import React, { useContext, useState, useEffect } from 'react';
import { TPage, TAvatar, TSearchBar, HapticButton } from '../components';
import {
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonBadge,
  IonItemDivider,
  IonIcon
} from '@ionic/react';
import { AppContext } from '../context/AppContext';
import useRequireSettingsAdmin from '../hooks/useRequireSettingsAdmin';
import moment from 'moment';
import { personAdd, ellipsisVertical, time, calendar, construct } from 'ionicons/icons';
import { appNotification, loadingIndicator, http, actionSheet } from '../core';
import {
  clientUsersService,
  leadsService,
  clientService,
  userService
} from '../services';
import { ReassignLeadsModal } from '../components/modals';

const ClientUsersPage: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const { clientUsers, selectedClientId, selectedClient } = state;
  const authUser = state.user;
  const [searchValue, setSearchValue] = useState('');
  const [users, setUsers] = useState(clientUsers);
  const [leadAssignModalUser, setLeadAssignModalUser] = useState<any>();
  const [leadAssignCount, setLeadAssignCount] = useState(0);
  const [showStaff, setShowStaff] = useState(false);

  useEffect(() => setUsers(clientUsers), [clientUsers, selectedClientId]);

  useEffect(() => {
    if (!showStaff) {
      setUsers(clientUsers.filter((u: any) => !userService.isStaff(u)));
    } else {
      setUsers(clientUsers);
    }
  }, [clientUsers, showStaff]);

  useRequireSettingsAdmin();
  const search = (val: string) => {
    setSearchValue(val);
    setUsers(
      val
        ? clientUsers.filter((u: any) =>
            `${u.full_name} ${u.email} ${u.cell_phone}`
              .toLowerCase()
              .match(val?.toLowerCase())
          )
        : clientUsers
    );
  };

  const removeUser = async (user: any) => {
    try {
      await clientUsersService.delete(selectedClientId, user);
      const updated = await clientUsersService.request(selectedClientId);
      dispatch({
        type: 'set',
        value: { clientUsers: updated }
      });
      setUsers(updated);
      setSearchValue('');
    } catch (e) {
      http.onHttpError(e);
    }
  };

  const onLeadsReassigned = async () => {
    try {
      await loadingIndicator.create();
      await removeUser(leadAssignModalUser);
      setLeadAssignModalUser(null);
    } finally {
      loadingIndicator.dismiss();
    }
  };

  const checkLeads = async (user: any) => {
    try {
      await loadingIndicator.create();
      const leadCount = await leadsService.getAssignedCount(
        selectedClientId,
        user.id
      );

      if (leadCount > 0) {
        setLeadAssignCount(leadCount);
        setLeadAssignModalUser(user);
      } else {
        await removeUser(user);
      }
    } catch (e) {
      http.onHttpError(e);
    } finally {
      loadingIndicator.dismiss();
    }
  };

  const confirmRemove = (user: any) => {
    appNotification.alert({
      message: `Are you sure you want to remove ${user.full_name}?`,
      header: 'Remove User?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Continue',
          handler() {
            checkLeads(user);
          }
        }
      ]
    });
  };

  const showUserActionSheet = (user: any) => {
    actionSheet.show([
      {
        text: 'Remove User',
        role: 'destructive',
        handler() {
          confirmRemove(user);
          return true;
        }
      },
      {
        text: 'Dismiss',
        role: 'cancel'
      }
    ]);
  };

  return (
    <TPage
      loading={false}
      preContent={
        <IonItemDivider>
          <TSearchBar value={searchValue} onSearch={search} />
          {authUser.is_admin && (
            <HapticButton
              icon={construct}
              color={showStaff ? 'secondary' : 'primary'}
              onClick={() => setShowStaff(!showStaff)}
            />
          )}
          <HapticButton
            icon={personAdd}
            routerLink={`/client/${selectedClientId}/create/user/`}
          />
        </IonItemDivider>
      }
    >
      <IonList>
        {users?.map?.((user: any) => (
          <IonItem
            key={user.id}
            routerLink={`/client/users/${selectedClientId}/${user.id}/`}
          >
            <TAvatar slot="start" alt={user.full_name} email={user.email} />
            <IonLabel className="ion-multiline">
              <IonText>
                <h3>{user.full_name}</h3>
                <p className="detail ion-text-wrap">
                  {user.cell_phone || 'No Phone'} • {user.email}
                </p>
                <p className="detail ion-text-wrap">
                  Last Login:{' '}
                  {user.last_login
                    ? moment(user.last_login).format('MMM DD, YYYY h:mma')
                    : 'Never'}
                </p>
                {((clientService.isRoundRobin(selectedClient) &&
                  userService.isRoundRobinEnabled(user)) ||
                  userService.canAccessInboundQueue(user)) && (
                  <p className="detail clock-status">
                    <IonIcon
                      icon={time}
                      style={{ marginRight: 5 }}
                      color={user.is_clocked_in ? 'success' : 'danger'}
                    />
                    <span>Clocked {user.is_clocked_in ? 'In' : 'Out'}</span>
                  </p>
                )}
                {authUser.is_staff && (
                  <p className="detail clock-status">
                    <IonIcon
                      icon={calendar}
                      style={{ marginRight: 5 }}
                      color={user.calendars_connected ? 'success' : 'danger'}
                    />
                    <span>Calendar {user.calendars_connected ? 'Connected' : 'Not Connected'}</span>
                  </p>
                )}
              </IonText>
            </IonLabel>
            {userService.isStaff(user) && (
              <IonBadge color="success" mode="ios" slot="end">
                Staff
              </IonBadge>
            )}
            {!userService.isStaff(user) && user.is_client_admin && (
              <IonBadge color="secondary" mode="ios" slot="end">
                Admin
              </IonBadge>
            )}
            {!userService.isStaff(user) && user.is_settings_admin && (
              <IonBadge color="tertiary" mode="ios" slot="end">
                Settings
              </IonBadge>
            )}
            <HapticButton
              icon={ellipsisVertical}
              slot="end"
              className="btn-sm-padding"
              onClick={(e: any) => showUserActionSheet(user)}
              cancelBubble
            />
          </IonItem>
        ))}
        {leadAssignModalUser && (
          <ReassignLeadsModal
            user={leadAssignModalUser}
            onConfirm={onLeadsReassigned}
            leadCount={leadAssignCount}
            onDidDismiss={() => {
              setLeadAssignCount(0);
              setLeadAssignModalUser(null);
            }}
          />
        )}
      </IonList>
    </TPage>
  );
};

export default ClientUsersPage;
