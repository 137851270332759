import React, { useContext, useCallback, useState, useMemo } from 'react';
import {
  TInfiniteScrollPage,
  BlastRow,
  TFab,
  PopoverSelector
} from '../components';
import {
  IonIcon,
  IonButton,
  IonItem,
  IonChip,
  IonLabel,
  IonText
} from '@ionic/react';
import { AppContext } from '../context/AppContext';
import { add, funnel, closeCircle, person, megaphone } from 'ionicons/icons';
import { blastsService, clientUsersService } from '../services';
import { BlastDetailModal } from '../components/modals';
import clientUsers from '../services/client-users';
import { haptics, util } from '../core';
import { useRouter, useDataLoader } from '../hooks';

const BlastsPage: React.FC = () => {
  const router = useRouter();
  const { state: appState } = useContext(AppContext);
  const { selectedClient } = appState;
  const [user, setUser] = useState('');
  const [userName, setUserName] = useState('');
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [blast, setBlast] = useState();
  const blastType = useMemo(() => router.query.type, [router]);
  const addedId = useMemo(() => router.query?.addedId, [router]);
  const isCustomer = useMemo(() => blastType === 'customers', [blastType]);
  const isCustomersEnabled = useMemo(() => selectedClient.dms_blasts_enabled, [
    selectedClient
  ]);
  const showUpgradePage = isCustomer && !isCustomersEnabled;

  const loadBlasts = useCallback(async () => {
    console.log(addedId); // this stops errors about unused dependency addedId
    return await blastsService.list({
      clientId: selectedClient.id,
      type: blastType,
      user
    });
  }, [selectedClient, user, blastType, addedId]);

  const {
    refresher,
    data,
    error,
    loadNext,
    next,
    loading,
    dispatch: dataDispatch
  } = useDataLoader(loadBlasts, true);

  return (
    <TInfiniteScrollPage
      loading={loading}
      items={data}
      error={error}
      hasNext={!!next}
      renderItem={(item: any) => (
        <BlastRow
          key={item.id}
          item={item}
          blastType={router.query.type}
          onClick={() => setBlast(item)}
          onBlastRemoved={(id: any) =>
            dataDispatch({ type: 'remove', value: (it: any) => it.id === id })
          }
        />
      )}
      onRefresh={refresher}
      onLoadNextPage={loadNext}
      toolbar={
        user && (
          <IonItem lines="full">
            <IonChip color="primary">
              <IonIcon icon={person} />
              <IonLabel>{userName}</IonLabel>
              <IonIcon
                icon={closeCircle}
                onClick={() => {
                  setUser('');
                }}
              />
            </IonChip>
          </IonItem>
        )
      }
      headerTool={
        !showUpgradePage ? (
          <IonButton
            fill="clear"
            slot="end"
            onClick={() => setFiltersOpen(true)}
          >
            <IonIcon slot="icon-only" color="primary" icon={funnel} />
          </IonButton>
        ) : (
          undefined
        )
      }
      fab={
        !showUpgradePage ? (
          <TFab
            icon={add}
            routerLink={
              blastType === 'leads' ? '/blasts/leads/choose-preset/' : './add/'
            }
          />
        ) : (
          undefined
        )
      }
      preContent={
        showUpgradePage && (
          <div className="blasts-upgrade-container">
            <div className="blasts-upgrade-icon-container">
              <IonIcon icon={megaphone} className="blasts-upgrade-icon" />
              <IonText>
                <h4>Want to blast to your past customers?</h4>
                <IonText className="detail">
                  Contact your performance manager
                  {selectedClient?.performance_manager
                    ? ` ${selectedClient.performance_manager.full_name}`
                    : ''}{' '}
                  today to have them enable customer blasts for you.
                </IonText>
                {/* <p className="detail">
                  Contact your performance manager
                  {selectedClient?.performance_manager
                    ? ` ${selectedClient.performance_manager.full_name}`
                    : ''}{' '}
                  today to have them enable customer blasts for you.
                </p> */}
              </IonText>
            </div>
            {selectedClient?.performance_manager && (
              <IonButton
                color="secondary"
                onClick={async () =>
                  util.tel(selectedClient.performance_manager.cell_phone)
                }
              >
                Contact {selectedClient.performance_manager.full_name}
              </IonButton>
            )}
          </div>
        )
      }
      zeroStateText={loading ? '' : 'No Text Blasts Found.'}
    >
      {filtersOpen && (
        <PopoverSelector
          isOpen={filtersOpen}
          items={appState.clientUsers}
          idField="id"
          filter={(it: any) => clientUsersService.getUserName(it)}
          onSelectionMade={(user: any) => {
            setFiltersOpen(false);
            setUser(user.id);
            setUserName(clientUsers.getUserName(user));
          }}
          placeholder="Search Users"
          renderLabel={clientUsersService.getUserName}
          onDidDismiss={() => {
            haptics.lightImpact();
            setFiltersOpen(false);
          }}
        />
      )}

      {blast && (
        <BlastDetailModal
          item={blast}
          blastType={blastType}
          onDidDismiss={() => setBlast(undefined)}
        />
      )}
    </TInfiniteScrollPage>
  );
};

export default BlastsPage;
