import React, { useState } from 'react';
import { IonPopover, IonList, IonButton, IonIcon } from '@ionic/react';
import TSelectItem from './TSelectItem';
import ButtonGrid from './ButtonGrid';
import { briefcase, checkmarkCircleOutline } from 'ionicons/icons';
import '../styles/components/AppointmentFilters.scss';
import { popoverController } from '@ionic/core';

export interface AppointmentFiltersProps {
  isOpen: boolean;
  onDidDismiss?: () => any;

  onApply?: (filters: any) => any;
}

const AppointmentFilters: React.FC<AppointmentFiltersProps> = ({
  isOpen,
  onDidDismiss,
  onApply
}) => {
  const [classification, setClassification] = useState('All');
  const [status, setStatus] = useState('All');

  return (
    <IonPopover
      isOpen={isOpen}
      mode="md"
      // cssClass="appointment-filters"
      onDidDismiss={onDidDismiss}
      className="appointment-filters"
    >
      <IonList lines="full">
        <TSelectItem
          label="Type"
          value={classification}
          onChange={(e: any) => setClassification(e.detail.value)}
          options={['All', 'Sales', 'Service', 'Employment']}
        >
          <IonIcon slot="start" icon={briefcase} className="ion-hide-md-down" />
        </TSelectItem>
        <TSelectItem
          label="Appointment Status"
          value={status}
          onChange={(e: any) => setStatus(e.detail.value)}
          options={['All', 'Pending', 'Missed', 'Cancelled', 'Showed', 'Sold']}
        >
          <IonIcon
            slot="start"
            icon={checkmarkCircleOutline}
            className="ion-hide-md-down"
          />
        </TSelectItem>
        <ButtonGrid>
          <IonButton
            expand="block"
            color="primary"
            onClick={() => {
              popoverController.dismiss();
              setStatus('All');
              setClassification('All');
              onApply?.({});
            }}
          >
            Reset Filters
          </IonButton>
          <IonButton
            expand="block"
            color="secondary"
            onClick={() => {
              popoverController.dismiss();
              onApply?.({
                status: status?.toLowerCase?.(),
                lead__classification: classification
              });
            }}
          >
            Apply Filters
          </IonButton>
        </ButtonGrid>
      </IonList>
    </IonPopover>
  );
};

export default AppointmentFilters;
