import React, { useContext, useState, useMemo } from 'react';
import TInput from './TInput';
import GiphyPickerButton from './GiphyPickerButton';
import EmojiPickerButton from './EmojiPickerButton';
import { useKeyboardShortcuts } from '../hooks';
import { native, util } from '../core';
import {
  ChatMessageBoxContext,
  expandQuickReply,
  setMessageFocus,
} from '../context/ChatMessageBoxContext';
import { LeadContext } from '../context/LeadContext';
import { AppContext } from '../context/AppContext';
export interface ChatMessageBoxProps {
  sendMessage: (e: any) => void;
}

const ChatMessageBox: React.FC<ChatMessageBoxProps> = ({ sendMessage }) => {
  const appContext = useContext(AppContext);
  const leadContext = useContext(LeadContext);
  const { leadClient, lead } = leadContext.state;
  const giphyEnabled = leadClient.gif_search_enabled;
  const [shortcodes, setShortcodes] = useState<any>();
  const [selectedShortCodeIndex, setSelectedShortCodeIndex] = useState(0);
  const { state, dispatch } = useContext(ChatMessageBoxContext);

  const { notificationGroups, allNotificationGroups } = state;

  const clientUsernames = useMemo(
    () => notificationGroups.map((it) => it.username),
    [notificationGroups]
  );

  const filterGroups = (mentions: string[]) => {
    const searchString = mentions.length
      ? mentions[mentions.length - 1].substring(1)
      : '';

    const re = new RegExp(util.regexpEscape(searchString), 'i');
    const filteredUsers = allNotificationGroups.filter((it: any) => {
      return it.name.match(re) || it.username.match(re);
    });

    return {
      notificationGroups: filteredUsers,
      mentionSelectedIndex: 0,
    };
  };

  const fillShortcode = (e?: any) => {
    const code = e ?? shortcodes[selectedShortCodeIndex];
    const reply = appContext.state.shortcodes[code];
    setShortcodes([]);
    expandQuickReply(reply, lead);
    dispatch({ type: 'set', value: { shortcode: code } });
  };

  const doSendMessage = (e: any) => {
    if (shortcodes?.length) {
      return fillShortcode();
    }

    sendMessage(e);
  };

  const searchShortcodes = async (value: string) => {
    const keys = Object.keys(appContext.state.shortcodes);
    const search = value.substring(value.lastIndexOf(':'));
    setShortcodes(keys.filter((it) => it.match(util.regexpEscape(search))));
  };

  const checkMentionsAndShortcodes = (value: string) => {
    let updates = {} as any;
    const shouldShowShortcut = !!value.match(/(^|\s+):/);

    if (shouldShowShortcut) {
      searchShortcodes(value);
    } else {
      setShortcodes(null);
      const shouldShowMentions = !!value.match(/@\w+$/);
      if (shouldShowMentions) {
        updates = Object.assign(updates, {
          showMentionsPopup: true,
          notificationGroups: state.allNotificationGroups,
        });
      } else if (state.showMentionsPopup) {
        updates.showMentionsPopup = false;
      }

      const mentions: string[] = value
        .split(' ')
        .filter((it: string) => it[0] === '@');

      if (state.showMentionsPopup) {
        updates = Object.assign(updates, filterGroups(mentions));
      }

      if (mentions.length && !state.internalOnly) {
        const valid = clientUsernames.find(
          (it: any) => mentions.indexOf(`@${it}`) > -1
        );

        if (valid && valid.length > 0) {
          updates.internalOnly = true;
        }
      }
    }

    dispatch({
      type: 'set',
      value: updates,
    });

    window.dispatchEvent(
      new CustomEvent('chatboxmessage:change', { detail: { value } })
    );
  };

  useKeyboardShortcuts({
    'CmdOrCtrl+M': setMessageFocus,
  });

  const onGiphySelected = (e: any) =>
    dispatch({
      type: 'set',
      value: { selectedGif: e.images.downsized.url },
    });

  const toggleEmojiPicker = () =>
    dispatch({ type: 'set', value: { emojiOpen: !state.emojiOpen } });

  return (
    <div className='chat-message-box'>
      <TInput
        valueChanged={checkMentionsAndShortcodes}
        onEnterPressed={doSendMessage}
        className='chat-message-box-input'
        placeholder='Message'
        id='chat-message-box-input'
        autoGrowMaxHeight={100}
        autoGrow
        autocapitalize='sentences'
        autoCapitalize='sentences'
        results={shortcodes}
        onSelected={fillShortcode}
        onSelectedIndexChanged={setSelectedShortCodeIndex}
        spellcheck
        multiline
      />
      {giphyEnabled && <GiphyPickerButton onSelection={onGiphySelected} />}
      {!native.isNative && <EmojiPickerButton onClick={toggleEmojiPicker} />}
    </div>
  );
};

export default ChatMessageBox;
