import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef
} from 'react';
import { AppointmentFilters, TPage, TLoading } from '../components';
import { IonButton, IonIcon } from '@ionic/react';
import { appointmentsService } from '../services';
import { AppContext } from '../context/AppContext';
import { Calendar, momentLocalizer, CalendarProps } from 'react-big-calendar';
import moment from 'moment';
import '../styles/pages/Appointments.scss';
import { AppointmentModal } from '../components/modals';
import { funnel } from 'ionicons/icons';
import { useKeyboardShortcuts } from '../hooks';
import { loadingIndicator, util, haptics } from '../core';
import { utcToZonedTime } from 'date-fns-tz';

const AppointmentsPage: React.FC = () => {
  const { state } = useContext(AppContext);
  const calendar = useRef<any>();
  const [loading, setLoading] = useState(true);
  const [appointments, setAppointments] = useState<any[]>([]);
  const [selectedAppointment, setSelectedAppointment] = useState<any>();
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState();
  const [date, setDate] = useState(moment());
  const [currentView, setCurrentView] = useState<CalendarProps['view']>(
    window.innerWidth < 500 ? 'day' : 'month'
  );

  const localizer = momentLocalizer(moment);

  const tz = util.formattedTimezone(state.selectedClient?.timezone);

  const loadAppointments = useCallback(
    async ({ search, showLoading }: any = {}) => {
      try {
        const clientId = state.selectedClientId;

        if (showLoading) {
          setLoading(true);
        }

        let truncType: any = 'month';
        switch (currentView) {
          case 'day':
            truncType = 'day';
            break;
          case 'week':
            truncType = 'week';
            break;
        }

        const { results } = await appointmentsService.list({
          page_size: 1000,
          filters,
          from: date.clone().startOf(truncType),
          to: date.clone().endOf(truncType),
          clientId,
          sort: '-appointment_time',
          search
        });
        await loadingIndicator.dismiss();
        setAppointments(results);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    [state.selectedClientId, filters, date, currentView]
  );

  useKeyboardShortcuts({
    'CmdOrCtrl+R': (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      loadAppointments({ showLoading: true });
    }
  });

  useEffect(() => {
    loadAppointments();
  }, [loadAppointments]);

  const onAppointmentChanged = (appt: any) => {
    setAppointments((a: any) => {
      const index = a.findIndex((it: any) => it.id === appt.id);
      if (index > -1) {
        a.splice(index, 1, appt);
      } else {
        a.push(appt);
      }
      return a;
    });
  };

  return (
    <TPage
      loading={loading}
      noContent
      id="appointments"
      headerTool={
        <IonButton
          fill="clear"
          className="btn-sm-padding"
          slot="end"
          onClick={() => {
            haptics.lightImpact();
            setShowFilters(true);
          }}
        >
          <IonIcon slot="icon-only" icon={funnel} />
        </IonButton>
      }
    >
      <TLoading visible={loading} absolute={false} />
      {!loading && (
        <Calendar
          ref={calendar}
          views={['month', 'day', 'week']}
          view={currentView}
          onView={async (view: any) => {
            util.defer(async () => {
              await loadingIndicator.create();
              setCurrentView(view);
            });
          }}
          className="appointments-calendar"
          localizer={localizer}
          onNavigate={async (e: any, i: any, a: any) => {
            if (a !== 'DATE') {
              await loadingIndicator.create();
            }
            setDate(moment(e));
          }}
          events={appointments?.map?.((app: any) => ({
            id: app.id,
            status: app.status,
            title: app.get_lead_info?.name ?? app.id,
            start: utcToZonedTime(app.appointment_time, tz),
            end: utcToZonedTime(
              moment(app.appointment_time)
                .add(1, 'hour')
                .toDate(),
              tz
            )
          }))}
          onSelectEvent={(e: any) => {
            const app = appointments.find((it: any) => it.id === e.id);
            setSelectedAppointment(app);
          }}
          eventPropGetter={(it: any) => ({
            className: `status-${it.status}`
          })}
          startAccessor="start"
          endAccessor="end"
        />
      )}
      {selectedAppointment && (
        <AppointmentModal
          isOpen={true}
          onAppointmentChanged={onAppointmentChanged}
          onDidDismiss={() => setSelectedAppointment(null)}
          appointment={selectedAppointment}
        />
      )}
      <AppointmentFilters
        isOpen={showFilters}
        onDidDismiss={() => setShowFilters(false)}
        onApply={async (e: any) => {
          await loadingIndicator.create();
          setFilters(e);
        }}
      />
    </TPage>
  );
};

export default AppointmentsPage;
